// eslint-disable-next-line no-restricted-imports,import/no-internal-modules
import { createTheme } from '@mui/material/styles'

import { Colors, colors } from './colors'
import { mrSansSerifFontStack } from './font-utils'
import {
  EFontWeight,
  Typography,
  typography as allTypography,
} from './typography'

//Module augmentation to add types for theme extensions
//https://mui.com/material-ui/customization/theming/#custom-variables
declare module '@mui/material/styles' {
  interface Theme {
    extension: {
      colors: Colors
      typography: Typography
    }
  }
  interface ThemeOptions {
    extension?: {
      colors?: Colors
      typography?: Typography
    }
  }
}

export const theme = createTheme({
  typography: {
    fontFamily: mrSansSerifFontStack('Poppins'),
    fontWeightMedium: EFontWeight.medium,
    fontWeightRegular: EFontWeight.regular,
    fontSize: 16,
  },
  palette: {
    primary: {
      main: colors.base.blueSky,
      light: colors.base.littleBoyBlue,
      dark: colors.base.deepBlue,
    },
    secondary: {
      main: colors.base.freshMint,
      light: colors.base.lightMint,
      dark: colors.base.greenLeaf,
    },
    grey: colors.neutral,
    divider: colors.divider.lightGray,
    text: {
      primary: colors.text.softBlack,
      secondary: colors.text.bodyGray,
    },
    error: {
      main: colors.semantic.error,
      light: colors.semantic.errorGlow,
    },
    common: {
      black: colors.text.black,
      white: colors.text.white,
    },
  },
  components: {
    MuiBackdrop: {
      styleOverrides: {
        root: {
          backgroundColor: 'rgba(0, 0, 0, 0.8)',
        },
      },
    },
  },
  extension: {
    colors,
    typography: allTypography,
  },
})
