import { useAuth0 } from '@auth0/auth0-react'
import { Box, Popover, styled, Theme } from '@mui/material'
import React, { FC, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { DashboardIcon, DocumentIcon, LogoutIcon, Text } from '../components'

export const DesktopHeader: FC<{
  onClose: () => void
  element: HTMLElement | null
}> = ({ element, onClose }) => {
  const [anchorEl, setAnchorEl] = useState(element)
  const open = Boolean(anchorEl)
  const navigate = useNavigate()

  const { logout } = useAuth0()

  const navigateTo = (url: string) => {
    setAnchorEl(null)
    onClose()
    navigate(url)
  }
  const handleClose = () => {
    setAnchorEl(null)
    onClose()
  }

  return (
    <Box>
      <StyledPopover
        disablePortal
        keepMounted
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        BackdropProps={{ invisible: true }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Container>
          <StyledMenuItem onClick={() => navigateTo('/')}>
            <DashboardIcon />{' '}
            <Text
              use={['p', 'interactive.buttonLabel16']}
              color="text.softBlack"
            >
              {'Dashboard'}
            </Text>
          </StyledMenuItem>

          <StyledMenuItem onClick={() => navigateTo('/documents')}>
            <DocumentIcon />{' '}
            <Text
              use={['p', 'interactive.buttonLabel16']}
              color="text.softBlack"
            >
              {'Documents'}
            </Text>
          </StyledMenuItem>
          <StyledMenuItem
            onClick={() =>
              logout({
                returnTo: window.location.origin,
              })
            }
          >
            <LogoutIcon />{' '}
            <Text
              use={['p', 'interactive.buttonLabel16']}
              color="text.softBlack"
            >
              {'Log out'}
            </Text>
          </StyledMenuItem>
        </Container>
      </StyledPopover>
    </Box>
  )
}

const Container = styled(Box)(({ theme }: { theme: Theme }) => ({
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  padding: '8px 16px',
  gap: '16px',
  background: theme.extension.colors.text.white,
  '& svg': {
    width: 24,
    height: 24,
  },
}))

const StyledMenuItem = styled(Box)({
  display: 'flex',
  gap: '16px',
  alignItems: 'center',
  width: '100%',
  cursor: 'pointer',
  '& svg': {
    width: 24,
    height: 24,
  },
})

const StyledPopover = styled(Popover)`
  .MuiBackdrop-root {
    background: rgba(0, 0, 0, 0.5);
    opacity: 0 !important;
  }
`
