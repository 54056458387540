import { Box, Dialog, DialogContent, styled, Theme } from '@mui/material'
import React, { FC } from 'react'
import { Button, CloseIcon, Icon, Text } from 'src/libs/common/components'

export type ConfirmationDialogProps = {
  open: boolean
  onLeaveBtnClick: () => void
  onStayBtnClick: () => void
}

export const ConfirmationDialog: FC<ConfirmationDialogProps> = ({
  onLeaveBtnClick,
  onStayBtnClick,
  open,
}) => {
  return (
    <Dialog open={open}>
      <StyledDialogContent>
        <StyledIcon onClick={onStayBtnClick} use={CloseIcon} />
        <Container>
          <TextContainer>
            <Text color="text.softBlack" use={['p', 'subheadings.16b']}>
              {'Leaving this page?'}
            </Text>
            <Text use={['p', 'subheadings.14m']}>
              {'Files that are uploading will not be saved.'}
            </Text>
          </TextContainer>
          <ButtonContainer>
            <Button onClick={onLeaveBtnClick} type="tertiary">
              {'Leave'}
            </Button>
            <Button onClick={onStayBtnClick}>{'Stay'}</Button>
          </ButtonContainer>
        </Container>
      </StyledDialogContent>
    </Dialog>
  )
}

const StyledDialogContent = styled(DialogContent)(
  ({ theme }: { theme: Theme }) => ({
    background: theme.extension.colors.text.white,
    borderRadius: 12,
    position: 'relative',
    padding: 24,
  }),
)

const Container = styled(Box)(({ theme }: { theme: Theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '24px',
  padding: 0,
}))

const TextContainer = styled(Box)(({ theme }: { theme: Theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '8px',
  padding: 0,
}))

const ButtonContainer = styled(Box)(({ theme }: { theme: Theme }) => ({
  display: 'flex',
  alignSelf: 'stretch',
  alignItems: 'flex-start',
  gap: '8px',
  padding: 0,
  '& button': {
    flex: '1 0 auto',
  },
}))
const StyledIcon = styled(Icon)(({ theme }: { theme: Theme }) => ({
  position: 'absolute',
  top: 10,
  right: 10,
  cursor: 'pointer',
  '& svg': {
    height: 24,
    width: 24,
  },
}))
