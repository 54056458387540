/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { Alert, AlertTitle, Box, Grid, Stack, Typography } from '@mui/material'
import React, { useContext } from 'react'

import { ExpandedLoanApplication, getExpandedLoanApplications } from '../../api'
import { LoadingIcon } from '../common'
import { trackPage } from '../common/components'
import { UserContext } from '../context'

import { InProgressLoan } from './in-progress-loan'
import { NewLoanApplication } from './new-loan-application'
import { PreviousLoan } from './previous-loan'
import { UploadDocBanner } from './upload-doc-banner'

export function filterLoans(
  loans: ExpandedLoanApplication[],
): ExpandedLoanApplication[] {
  return Object.values(
    [...loans]
      // Sort by deduplication precedence, which is simplifiedStatus priority followed by updatedAt
      .sort((a, b) => {
        const priority = ['funded', 'inProgress', 'expired']
        return (
          priority.indexOf(a.loanApplication.simplifiedStatus) -
            priority.indexOf(b.loanApplication.simplifiedStatus) ||
          b.loanApplication.updatedAt.getTime() -
            a.loanApplication.updatedAt.getTime()
        )
      })
      // Reduce to filter out duplicate loans for the same vehicle
      .reduce((acc, elem) => {
        const key =
          elem.vehicle?.year && elem.vehicle?.make && elem.vehicle.model
            ? `${elem.vehicle?.year}${elem.vehicle?.make}${elem.vehicle?.model}`
            : ''
        const included = (acc[key] ??= [])
        // Include all unknown vehicle loans, the first loan for a vehicle, and all funded loans
        if (
          key === '' ||
          included.length === 0 ||
          elem.loanApplication.simplifiedStatus === 'funded'
        ) {
          included.push(elem)
        }
        return acc
      }, {} as { [key: string]: ExpandedLoanApplication[] }),
  ).flat()
}

export function partitionLoans(loans: ExpandedLoanApplication[]): {
  inProgress: ExpandedLoanApplication[]
  previous: ExpandedLoanApplication[]
} {
  return (
    loans
      // Sort by display date, which uses fundedAt/expiredAt for funded/expired
      .sort((a, b) => {
        return (
          (
            b.loanApplication.fundedAt ??
            b.loanApplication.expiredAt ??
            b.loanApplication.updatedAt
          ).getTime() -
          (
            a.loanApplication.fundedAt ??
            a.loanApplication.expiredAt ??
            a.loanApplication.updatedAt
          ).getTime()
        )
      })
      // Partition into inProgress/previous loans
      .reduce(
        (acc, elem) => {
          if (elem.loanApplication.simplifiedStatus === 'inProgress') {
            acc.inProgress.push(elem)
          } else {
            acc.previous.push(elem)
          }
          return acc
        },
        { inProgress: [], previous: [] } as {
          inProgress: ExpandedLoanApplication[]
          previous: ExpandedLoanApplication[]
        },
      )
  )
}

export function Dashboard(): JSX.Element {
  const user = useContext(UserContext)

  const [state, setState] = React.useState<{
    loading?: true
    loaded?: {
      inProgress: ExpandedLoanApplication[]
      previous: ExpandedLoanApplication[]
    }
    errored?: true
  }>({ loading: true })

  React.useEffect(() => {
    trackPage()
  }, [])

  React.useEffect(() => {
    const controller = new AbortController()
    getExpandedLoanApplications(
      user.token,
      user.customerUuid,
      controller.signal,
    )
      .then((loans) => {
        setState({ loaded: partitionLoans(filterLoans(loans)) })
      })
      .catch((error) => {
        if (!controller.signal.aborted) {
          console.error('Error loading loan applications: ', error)
          setState({ errored: true })
        }
      })
    return () => controller.abort()
  }, [user.token, user.customerUuid])

  function LoadingState(): JSX.Element {
    return (
      <React.Fragment>
        <LoadingIcon sx={{ fontSize: '40px' }} />
        <Typography sx={{ mt: '16px !important' }}>
          We&apos;re pulling up your loan applications...
        </Typography>
      </React.Fragment>
    )
  }

  function LoadedState(): JSX.Element {
    return (
      <React.Fragment>
        {state.loaded!.inProgress.length !== 0 ? (
          <Box component="section" sx={{ minWidth: 1 }}>
            <Typography component="h2" display="none">
              In-progress applications
            </Typography>
            <Grid container spacing={{ xs: 2, sm: 4 }} role="list">
              {state.loaded!.inProgress.map((e) => (
                <Grid key={e.loanApplication.uuid} item xs={12} role="listitem">
                  <InProgressLoan expandedLoanApplication={e} />
                </Grid>
              ))}
            </Grid>
          </Box>
        ) : (
          <UploadDocBanner />
        )}
        <NewLoanApplication />
        {(state.loaded!.previous.length || null) && (
          <Box component="section" sx={{ minWidth: 1 }}>
            <Typography
              component="h2"
              sx={{ mb: 2, fontSize: { xs: 24, sm: 32 }, fontWeight: 700 }}
            >
              Previous applications
            </Typography>
            <Grid container spacing={{ xs: 2, sm: 4 }} role="list">
              {state.loaded!.previous.map((e) => (
                <Grid
                  key={e.loanApplication.uuid}
                  item
                  xs={12}
                  sm={6}
                  role="listitem"
                >
                  <PreviousLoan expandedLoanApplication={e} />
                </Grid>
              ))}
            </Grid>
          </Box>
        )}
      </React.Fragment>
    )
  }

  function ErroredState(): JSX.Element {
    return (
      <Alert severity="error">
        <AlertTitle>Error loading loan applications.</AlertTitle>
        It&apos;s not you, it&apos;s us.
        <br />
        Call 1-877-445-0070 for assistance or try again later.
      </Alert>
    )
  }

  return (
    <Grid
      container
      direction="column"
      spacing={{ xs: 3, sm: 6 }}
      sx={{ minHeight: 1 }}
    >
      <Grid item xs="auto">
        <Typography
          textTransform="uppercase"
          sx={{
            mb: 1,
            fontSize: { xs: 16, sm: 18 },
            fontWeight: 600,
            color: '#008765',
          }}
        >
          Hi {user.displayName}
        </Typography>
        <Typography
          component="h1"
          sx={{ fontSize: { xs: 32, sm: 48 }, fontWeight: 700 }}
        >
          Welcome to Caribou
        </Typography>
      </Grid>
      <Grid item xs>
        <Stack
          spacing={{ xs: 3, sm: 6 }}
          justifyContent="center"
          alignItems="center"
          sx={{ height: 1 }}
        >
          {state.loading && <LoadingState />}
          {state.loaded && <LoadedState />}
          {state.errored && <ErroredState />}
        </Stack>
      </Grid>
    </Grid>
  )
}
