import { Box, styled } from '@mui/material'

export const StackFields = styled(Box)(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  // for some reason if it's not wrapped in a string template, this style gets omitted
  rowGap: theme.spacing(3),
  columnGap: theme.spacing(4),

  [theme.breakpoints.down('sm')]: {
    rowGap: theme.spacing(2),
    gridTemplateColumns: '1fr',
  },
}))
