import { SvgIcon, SvgIconProps } from '@mui/material'
import React from 'react'

export const DocumentUploadedIcon: React.FC<SvgIconProps> = ({
  htmlColor = 'black',
  ...props
}) => (
  <SvgIcon viewBox="0 0 32 32" htmlColor={htmlColor} {...props}>
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22.3829 28.3333H6.9427C5.68694 28.3297 4.67006 27.3851 4.6665 26.2189V5.44776C4.67006 4.28151 5.68694 3.33689 6.9427 3.33331H16.9808V6.94907C16.9814 8.89502 18.6793 10.4726 20.7744 10.4731H24.6665V26.2189C24.6594 27.3865 23.6398 28.3303 22.3829 28.3333ZM23.6043 9.06351H20.7744C19.5187 9.06021 18.5018 8.11559 18.4982 6.94907V4.32721L23.6043 9.06351Z"
        fill="#66ACEE"
      />
      <rect
        x="8.6665"
        y="11.3333"
        width="7.33333"
        height="1.52667"
        rx="0.763333"
        fill="white"
      />
      <rect
        x="8.6665"
        y="14.86"
        width="12.9523"
        height="1.5238"
        rx="0.761898"
        fill="white"
      />
      <rect
        x="8.6665"
        y="18.3838"
        width="12.9523"
        height="1.5238"
        rx="0.761898"
        fill="white"
      />
      <mask
        id="path-5-outside-1_650_5824"
        maskUnits="userSpaceOnUse"
        x="14"
        y="14"
        width="16"
        height="16"
        fill="black"
      >
        <rect fill="white" x="14" y="14" width="16" height="16" />
        <path d="M21.6668 16C18.542 16 16 18.542 16 21.6666C16 24.7913 18.542 27.3333 21.6668 27.3333C24.7913 27.3333 27.3333 24.7913 27.3333 21.6666C27.3333 18.542 24.7913 16 21.6668 16ZM25.0311 20.5994L20.9715 24.659C20.8011 24.8294 20.5776 24.9147 20.3543 24.9147C20.1311 24.9147 19.9078 24.8296 19.7374 24.659L18.186 23.1076C17.8452 22.7671 17.8452 22.2145 18.186 21.8737C18.5266 21.5329 19.0791 21.5329 19.4199 21.8737L20.3543 22.8081L23.7972 19.3655C24.1378 19.0247 24.6903 19.0247 25.0311 19.3655C25.3719 19.7061 25.3719 20.2586 25.0311 20.5994Z" />
      </mask>
      <path
        d="M21.6668 16C18.542 16 16 18.542 16 21.6666C16 24.7913 18.542 27.3333 21.6668 27.3333C24.7913 27.3333 27.3333 24.7913 27.3333 21.6666C27.3333 18.542 24.7913 16 21.6668 16ZM25.0311 20.5994L20.9715 24.659C20.8011 24.8294 20.5776 24.9147 20.3543 24.9147C20.1311 24.9147 19.9078 24.8296 19.7374 24.659L18.186 23.1076C17.8452 22.7671 17.8452 22.2145 18.186 21.8737C18.5266 21.5329 19.0791 21.5329 19.4199 21.8737L20.3543 22.8081L23.7972 19.3655C24.1378 19.0247 24.6903 19.0247 25.0311 19.3655C25.3719 19.7061 25.3719 20.2586 25.0311 20.5994Z"
        fill="#008765"
      />
      <path
        d="M21.6668 16C18.542 16 16 18.542 16 21.6666C16 24.7913 18.542 27.3333 21.6668 27.3333C24.7913 27.3333 27.3333 24.7913 27.3333 21.6666C27.3333 18.542 24.7913 16 21.6668 16ZM25.0311 20.5994L20.9715 24.659C20.8011 24.8294 20.5776 24.9147 20.3543 24.9147C20.1311 24.9147 19.9078 24.8296 19.7374 24.659L18.186 23.1076C17.8452 22.7671 17.8452 22.2145 18.186 21.8737C18.5266 21.5329 19.0791 21.5329 19.4199 21.8737L20.3543 22.8081L23.7972 19.3655C24.1378 19.0247 24.6903 19.0247 25.0311 19.3655C25.3719 19.7061 25.3719 20.2586 25.0311 20.5994Z"
        stroke="white"
        strokeWidth="4"
        mask="url(#path-5-outside-1_650_5824)"
      />
    </svg>
  </SvgIcon>
)
