import { createContext } from 'react'

import { UploadFileResult } from '../common/components/types'

export const FileUploadContext = createContext<{
  file?: UploadFileResult
  onCancelButtonClick?: (file: UploadFileResult) => void
  onRetryButtonClick?: (file: UploadFileResult) => void
  progress?: number
}>({})
