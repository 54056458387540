import React, { FC } from 'react'

export const withProviders =
  <TProps extends {}>(...Providers: ReadonlyArray<FC<TProps>>) =>
  (Component: FC<TProps>): FC<TProps> => {
    return [...Providers, Component].reduce((Prev: any, Curr) => {
      return (props) => (
        <Prev>
          <Curr {...props} />
        </Prev>
      )
    })
  }
