import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Grid,
  Paper,
  Snackbar,
  Stack,
  Typography,
  styled,
  Theme,
  useMediaQuery,
} from '@mui/material'
// eslint-disable-next-line import/no-internal-modules
import { useTheme } from '@mui/material/styles'
import React, { useContext, useRef, useEffect } from 'react'
import { useLocation } from 'react-router-dom'

import { getLoanApplicationResume, newLoanApplication } from '../../api'
import { sendTrackEvent } from '../common/components'
import { UserContext } from '../context'
import { onScrollFinish, useScrollIntoView, useShake } from '../hooks'

import { GetStartedIcon } from './get-started-icon'
import { NewLoanIcon } from './new-loan-icon'

export function NewLoanApplication(): JSX.Element {
  const theme = useTheme()
  const mobile = useMediaQuery(theme.breakpoints.between('xs', 'sm'))
  const user = useContext(UserContext)
  const ref = useRef<HTMLDivElement>(null)
  const { search } = useLocation()
  const scroll = new URLSearchParams(search).get('refi-new-car')
  const scrollIntoView = useScrollIntoView()
  const shake = useShake()

  const [state, setState] = React.useState<{
    pending?: true
    errored?: true
    loaded?: {
      loanApplicationUuid: string
      resumeUrl: string
    }
  }>({})

  React.useEffect(() => {
    if (state.loaded) {
      sendTrackEvent('Application Created', {
        createdAt: new Date().toISOString(),
        customerUuid: user.customerUuid,
        email: user.email,
        loanApplicationUuid: state.loaded.loanApplicationUuid,
        path: '/',
      })
      window.location.href = state.loaded.resumeUrl
    }
  }, [state.loaded, user])

  useEffect(() => {
    const current = ref.current
    if (scroll && current) {
      const scrolling = scrollIntoView(current)
      return scrolling ? onScrollFinish(() => shake(current)) : shake(current)
    }
  }, [scroll, scrollIntoView, shake])

  function onClick() {
    if (!state.pending) {
      setState({ pending: true })
      newLoanApplication(user.token, user.customerUuid)
        .then(({ loanApplicationUuid }) => {
          return getLoanApplicationResume(user.token, loanApplicationUuid).then(
            ({ resumeUrl }) => {
              setState({ loaded: { loanApplicationUuid, resumeUrl } })
            },
          )
        })
        .catch((error) => {
          console.error('Error creating new loan application: ', error)
          setState({ errored: true })
        })
    }
  }

  return (
    <StyledPaper
      id="new-loan-application"
      ref={ref}
      elevation={3}
      sx={{
        width: 1,
        borderRadius: 4,
        boxShadow: '0px 32px 48px 0px #767C7714, 0px -4px 20px 0px #0000000A',
      }}
    >
      <Box
        component={mobile ? Button : undefined}
        onClick={mobile ? onClick : undefined}
        sx={{ p: { xs: 2, sm: 3 } }}
      >
        <Grid
          container
          spacing={2}
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item xs>
            <Stack direction="row" spacing={2} alignItems="center">
              <Box
                sx={{
                  p: '4px',
                  borderRadius: '8px',
                  backgroundColor: '#F8F8F8',
                  lineHeight: 1,
                }}
              >
                <NewLoanIcon sx={{ fontSize: 32 }} />
              </Box>
              <Typography
                component="h2"
                sx={{
                  textAlign: 'left',
                  textTransform: 'none',
                  fontSize: { xs: 14, sm: 18 },
                  fontWeight: 500,
                  color: 'black',
                }}
              >
                Have another vehicle? Start a new refinance application.
              </Typography>
            </Stack>
          </Grid>
          <Grid item xs="auto">
            {mobile ? (
              <Box
                sx={{
                  p: '4px',
                  lineHeight: 1,
                }}
              >
                <GetStartedIcon />
              </Box>
            ) : (
              <Button
                onClick={onClick}
                sx={{
                  py: 2,
                  px: 8,
                  border: '1px solid black',
                  textTransform: 'none',
                  color: 'black',
                  '&:hover': {
                    color: '#0054A3 !important',
                    borderColor: '#0075E3',
                  },
                }}
              >
                <Typography sx={{ fontSize: 18, fontWeight: 600 }}>
                  Get started
                </Typography>
              </Button>
            )}
          </Grid>
        </Grid>
        <Snackbar
          open={state.errored === true}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          autoHideDuration={6000}
          onClose={() => setState({})}
        >
          <Alert severity="error" sx={{ width: '100%' }}>
            <AlertTitle>Error creating new loan application.</AlertTitle>
            It&apos;s not you, it&apos;s us.
            <br />
            Call 1-877-445-0070 for assistance or try again later.
          </Alert>
        </Snackbar>
      </Box>
    </StyledPaper>
  )
}

const StyledPaper = styled(Paper)(({ theme }: { theme: Theme }) => ({
  marginTop: `32px !important`,
  [theme.breakpoints.down('sm')]: {
    marginTop: `24px !important`,
  },
}))
