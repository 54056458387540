import { SvgIcon, SvgIconProps } from '@mui/material'
import React from 'react'

export const CloseIcon: React.FC<SvgIconProps> = ({
  htmlColor = 'black',
  ...props
}) => (
  <SvgIcon viewBox="0 0 20 20" htmlColor={htmlColor} {...props}>
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.41058 4.41058C4.73602 4.08514 5.26366 4.08514 5.58909 4.41058L15.5891 14.4106C15.9145 14.736 15.9145 15.2637 15.5891 15.5891C15.2637 15.9145 14.736 15.9145 14.4106 15.5891L4.41058 5.58909C4.08514 5.26366 4.08514 4.73602 4.41058 4.41058Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.5889 4.41058C15.2635 4.08514 14.7359 4.08514 14.4104 4.41058L4.41042 14.4106C4.08498 14.736 4.08498 15.2637 4.41042 15.5891C4.73586 15.9145 5.26349 15.9145 5.58893 15.5891L15.5889 5.58909C15.9144 5.26366 15.9144 4.73602 15.5889 4.41058Z"
        fill="black"
      />
    </svg>
  </SvgIcon>
)
