import { SvgIcon, SvgIconProps } from '@mui/material'
import React from 'react'

export const OriginalFinanceContractIcon: React.FC<SvgIconProps> = ({
  htmlColor = 'black',
  ...props
}) => (
  <SvgIcon viewBox="0 0 48 48" htmlColor={htmlColor} {...props}>
    <path
      d="M42.0016 12.0884C42.0016 10.3415 40.5724 8.91223 38.8255 8.91223H15.0034C13.2565 8.91223 11.8272 10.3415 11.8272 12.0884V41.7324C11.8272 43.4793 13.2565 44.9086 15.0034 44.9086H33L41.9991 36.4388L42.0016 12.0884Z"
      fill="#66ACEE"
    />
    <path
      d="M11.8268 12.0879C11.8268 10.3411 13.2561 8.9118 15.003 8.9118H36.1783V6.26501C36.1783 4.51813 34.749 3.08887 33.0022 3.08887H9.18005C7.43317 3.08887 6.00391 4.51813 6.00391 6.26501V35.909C6.00391 37.6559 7.43317 39.0852 9.18005 39.0852H11.8268V12.0879Z"
      fill="#CCE3F9"
    />
    <path
      d="M36.1761 36.4407H41.9991L33 44.9104V39.6168C33 37.8699 34.4293 36.4407 36.1761 36.4407Z"
      fill="#CCE3F9"
    />
    <path
      d="M19.766 22.9415H34.0586C34.4975 22.9415 34.8527 22.5857 34.8527 22.1474C34.8527 21.7086 34.4974 21.3534 34.0586 21.3534H19.766C19.3272 21.3534 18.972 21.7086 18.972 22.1474C18.972 22.5857 19.3271 22.9415 19.766 22.9415Z"
      fill="white"
    />
    <path
      d="M19.766 17.647H34.0587C34.4975 17.647 34.8527 17.2913 34.8527 16.853C34.8527 16.4142 34.4974 16.059 34.0587 16.059H19.766C19.3272 16.059 18.972 16.4142 18.972 16.853C18.972 17.2913 19.3271 17.647 19.766 17.647Z"
      fill="white"
    />
    <path
      d="M19.766 28.2354H34.0586C34.4975 28.2354 34.8527 27.8796 34.8527 27.4414C34.8527 27.0026 34.4974 26.6473 34.0586 26.6473H19.766C19.3272 26.6473 18.972 27.0026 18.972 27.4414C18.972 27.8796 19.3271 28.2354 19.766 28.2354Z"
      fill="white"
    />
    <path
      d="M19.766 33.5252H27.7063C28.1451 33.5252 28.5004 33.1694 28.5004 32.7312C28.5004 32.2923 28.1451 31.9371 27.7063 31.9371H19.766C19.3272 31.9371 18.972 32.2923 18.972 32.7312C18.972 33.1694 19.3271 33.5252 19.766 33.5252Z"
      fill="white"
    />
  </SvgIcon>
)
