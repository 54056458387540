/* eslint-disable @typescript-eslint/no-explicit-any */
import { Theme } from '@mui/material'

import { Typography } from '../theme/typography'

type AllTypography = Typography | Theme['extension']['typography']
type Colors = Theme['extension']['colors']

type Key = string | number

// Smallest value is required
export type BreakpointDictionary<T> = { xs: T; sm?: T; md?: T; lg?: T; xl?: T }
export type Breakpoints = keyof BreakpointDictionary<unknown>

type Path2<T extends {}> = {
  [K in keyof T]: `${K & Key}.${keyof T[K] & Key}`
}[keyof T]

// eslint-disable-next-line @typescript-eslint/ban-types
type PathDeep<T, TCurrentPath extends string = ''> = T extends Record<
  Key,
  unknown
>
  ? {
      [K in keyof T]: PathDeep<
        T[K],
        TCurrentPath extends '' ? K & Key : `${TCurrentPath}.${K & Key}`
      >
    }[keyof T]
  : TCurrentPath

export type TypographyPath =
  | Path2<AllTypography>
  | BreakpointDictionary<Path2<AllTypography>>

export type ColorPath = PathDeep<Colors>

export enum EFileType {
  License = 'License',
  Registration = 'Registration',
  Insurance = 'Insurance',
}

export enum EErrorFile {
  SizeExceed = 'SizeExceed',
  Empty = 'Empty',
  InvalidType = 'InvalidType',
  UploadFailed = 'UploadFailed',
}

export enum EFileStatus {
  NotStarted = 'NotStarted',
  InProgress = 'InProgress',
  Succeeded = 'Succeeded',
  Failed = 'Failed',
}

export type UploadFileResult = {
  error?: {
    message: string
    type: EErrorFile
  }
  status: EFileStatus
  data: File
}

export type UploadProgressFile = {
  [key: string]: {
    file: UploadFileResult
    progress: number
    controller?: AbortController
  }
}

const stipTypes: { [k: string]: number } = JSON.parse(
  window._env_.REACT_APP_STIP_TYPES.replace("'{", '{').replace("}'", '}'),
)

export enum EStipType {
  //BackLicense = stipTypes['BL'],
  //FrontLicense = stipTypes['FL'],
  //VehicleRegistration = stipTypes['VR'],
  //ProofOfInurance = stipTypes['PI'],
  //Odometer = stipTypes['OD'],
  OriginalFinanceContract = stipTypes['OFC'],
  //Misc = stipTypes['MI'],
}

const DEFAULT_STIPULATION_TYPES: number[] = JSON.parse(
  window._env_.REACT_APP_DEFAULT_STIPULATION_TYPES,
)

export const DefaultStipulationTypes = {
  DRIVERS_LICENSE_FRONT: DEFAULT_STIPULATION_TYPES[0],
  DRIVERS_LICENSE_BACK: DEFAULT_STIPULATION_TYPES[1],
  VEHICLE_REGISTRATION: DEFAULT_STIPULATION_TYPES[2],
  VEHICLE_INSURANCE: DEFAULT_STIPULATION_TYPES[3],
  ODOMETER: DEFAULT_STIPULATION_TYPES[4],
}

export type CustomerFile = {
  uuid: string
  name?: string
  loan_application?: string
  stipulation_type?: number
  uploaded_at: string
}

export type RequiredCustomerUpload = {
  stipulation_type: number
  title: string
  description: string
  uploaded: boolean
}
