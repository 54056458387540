/* eslint-disable @typescript-eslint/naming-convention */
import { CSSProperties } from 'react'

import { objectWithValues } from './utils'

type FontStyles = Pick<
  CSSProperties,
  'fontSize' | 'fontWeight' | 'lineHeight'
> &
  Partial<
    Pick<CSSProperties, 'letterSpacing' | 'textDecoration' | 'textTransform'>
  >

export enum EFontWeight {
  regular = 400,
  medium = 500,
  semibold = 600,
  bold = 700,
  black = 900,
}

const defineFontStyles = objectWithValues<FontStyles>()
/**
 * Taken from here
 * https://www.figma.com/file/Lr6bQVht1i1XARptEFgWMy/Boreal-Foundations%3A-Type?node-id=1%3A3
 */
export const typography = {
  headings: defineFontStyles({
    72: {
      fontSize: '4.5rem',
      fontWeight: EFontWeight.bold,
      lineHeight: '5rem',
    },
    64: {
      fontSize: '4rem',
      fontWeight: EFontWeight.bold,
      letterSpacing: '-1px',
      lineHeight: '4.5rem',
    },
    48: {
      fontSize: '3rem',
      fontWeight: EFontWeight.bold,
      lineHeight: '3.5rem',
    },
    40: {
      fontSize: '2.5rem',
      fontWeight: EFontWeight.bold,
      lineHeight: '3rem',
    },
    32: {
      fontSize: '2rem',
      fontWeight: EFontWeight.bold,
      lineHeight: '2.5rem',
    },
  }),
  subheadings: defineFontStyles({
    '28b': {
      fontSize: '1.75rem',
      fontWeight: EFontWeight.bold,
      lineHeight: '2.5rem',
    },
    '28m': {
      fontSize: '1.75rem',
      fontWeight: EFontWeight.medium,
      lineHeight: '2.5rem',
    },
    '24b': {
      fontSize: '1.5rem',
      fontWeight: EFontWeight.bold,
      lineHeight: '2.25rem',
    },
    '24m': {
      fontSize: '1.5rem',
      fontWeight: EFontWeight.bold,
      lineHeight: '2.25rem',
    },
    '18b': {
      fontSize: '1.125rem',
      fontWeight: EFontWeight.bold,
      lineHeight: '1.75rem',
    },
    '18m': {
      fontSize: '1.125rem',
      fontWeight: EFontWeight.medium,
      lineHeight: '2rem',
    },
    '16b': {
      fontSize: '1rem',
      fontWeight: EFontWeight.bold,
      lineHeight: '1.5rem',
    },
    '16sb': {
      fontSize: '1rem',
      fontWeight: EFontWeight.semibold,
      lineHeight: '1.5rem',
    },
    '16m': {
      fontSize: '1rem',
      fontWeight: EFontWeight.medium,
      lineHeight: '1.5rem',
    },
    '14b': {
      fontSize: '0.875rem',
      fontWeight: EFontWeight.bold,
      lineHeight: '1.5rem',
    },
    '14sb': {
      fontSize: '0.875rem',
      fontWeight: EFontWeight.semibold,
      lineHeight: '1.5rem',
    },
    '14m': {
      fontSize: '0.875rem',
      fontWeight: EFontWeight.medium,
      lineHeight: '1.5rem',
    },
  }),
  paragraphs: defineFontStyles({
    18: {
      fontSize: '1.125rem',
      fontWeight: EFontWeight.regular,
      lineHeight: '2rem',
    },
    16: {
      fontSize: '1rem',
      fontWeight: EFontWeight.regular,
      lineHeight: '1.5rem',
    },
    14: {
      fontSize: '0.875rem',
      fontWeight: EFontWeight.regular,
      lineHeight: '1.25rem',
    },
  }),
  interactive: defineFontStyles({
    buttonLabel18: {
      fontSize: '1.125rem',
      fontWeight: EFontWeight.semibold,
      lineHeight: '2rem',
    },
    buttonLabel16: {
      fontSize: '1rem',
      fontWeight: EFontWeight.semibold,
      lineHeight: '2rem',
    },
    inputLabel16: {
      fontSize: '1rem',
      fontWeight: EFontWeight.semibold,
      lineHeight: '1.25rem',
    },
    inputLabel14: {
      fontSize: '0.875rem',
      fontWeight: EFontWeight.semibold,
      lineHeight: '1.25rem',
    },
    inputText14: {
      fontSize: '0.875rem',
      fontWeight: EFontWeight.semibold,
      lineHeight: '2rem',
    },
    navItem16: {
      fontSize: '1rem',
      fontWeight: EFontWeight.semibold,
      lineHeight: '1.5rem',
    },
    hyperlink16b: {
      fontSize: '1rem',
      fontWeight: EFontWeight.bold,
      lineHeight: '1.5rem',
      textDecoration: 'underline',
    },
    hyperlink16: {
      fontSize: '1rem',
      fontWeight: EFontWeight.medium,
      lineHeight: '1.5rem',
      textDecoration: 'underline',
    },
    hyperlink14: {
      fontSize: '0.875rem',
      fontWeight: EFontWeight.medium,
      lineHeight: '1.5rem',
      textDecoration: 'underline',
    },
  }),
  uppercase: defineFontStyles({
    '16sb': {
      fontSize: '1rem',
      fontWeight: EFontWeight.semibold,
      lineHeight: '1.5rem',
      textTransform: 'uppercase',
    },
    '16b': {
      fontSize: '1rem',
      fontWeight: EFontWeight.black,
      lineHeight: '1.5rem',
      textTransform: 'uppercase',
    },
    14: {
      fontSize: '0.875rem',
      fontWeight: EFontWeight.black,
      lineHeight: '1.25rem',
      textTransform: 'uppercase',
    },
  }),
  brandGuide: defineFontStyles({
    '11': {
      fontSize: '0.6875rem',
      fontWeight: EFontWeight.medium,
      lineHeight: '1rem',
      textTransform: 'uppercase',
    },
  }),
}

// Do not edit this. This ensures that keys on the type are defined as constants
// Otherwise, you'd have to write an explicit type and duplicate the keys there
export type Typography = typeof typography
