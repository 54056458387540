import { SvgIcon, SvgIconProps } from '@mui/material'
import React from 'react'

export function UploadDocumentsIcon(props: SvgIconProps): JSX.Element {
  return (
    <SvgIcon viewBox="0 0 32 32" {...props}>
      <svg
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M28.001 8.05908C28.001 6.89449 27.0482 5.94165 25.8836 5.94165H10.0022C8.83761 5.94165 7.88477 6.89449 7.88477 8.05908V27.8218C7.88477 28.9864 8.83761 29.9392 10.0022 29.9392H21.9999L27.9993 24.2927L28.001 8.05908Z"
          fill="#3A6771"
        />
        <path
          d="M7.88488 8.05871C7.88488 6.89412 8.83773 5.94128 10.0023 5.94128H24.1192V4.17676C24.1192 3.01217 23.1664 2.05933 22.0018 2.05933H6.12036C4.95577 2.05933 4.00293 3.01217 4.00293 4.17676V23.9394C4.00293 25.104 4.95577 26.0569 6.12036 26.0569H7.88488V8.05871Z"
          fill="#9DB3B8"
        />
        <path
          d="M24.1174 24.2939H27.9994L22 29.9404V26.4114C22 25.2468 22.9528 24.2939 24.1174 24.2939Z"
          fill="#9DB3B8"
        />
        <path
          d="M13.1778 15.2943H22.7062C22.9988 15.2943 23.2356 15.0571 23.2356 14.765C23.2356 14.4724 22.9988 14.2356 22.7062 14.2356H13.1778C12.8853 14.2356 12.6484 14.4724 12.6484 14.765C12.6484 15.0571 12.8852 15.2943 13.1778 15.2943Z"
          fill="white"
        />
        <path
          d="M13.1778 11.7648H22.7062C22.9988 11.7648 23.2356 11.5276 23.2356 11.2354C23.2356 10.9429 22.9988 10.7061 22.7062 10.7061H13.1778C12.8853 10.7061 12.6484 10.9429 12.6484 11.2354C12.6484 11.5276 12.8852 11.7648 13.1778 11.7648Z"
          fill="white"
        />
        <path
          d="M13.1778 18.8236H22.7062C22.9988 18.8236 23.2356 18.5864 23.2356 18.2942C23.2356 18.0017 22.9988 17.7649 22.7062 17.7649H13.1778C12.8853 17.7649 12.6484 18.0017 12.6484 18.2942C12.6484 18.5864 12.8852 18.8236 13.1778 18.8236Z"
          fill="white"
        />
        <path
          d="M13.1778 22.3502H18.4714C18.7639 22.3502 19.0007 22.113 19.0007 21.8209C19.0007 21.5283 18.7639 21.2915 18.4714 21.2915H13.1778C12.8853 21.2915 12.6484 21.5283 12.6484 21.8209C12.6484 22.113 12.8852 22.3502 13.1778 22.3502Z"
          fill="white"
        />
      </svg>
    </SvgIcon>
  )
}
