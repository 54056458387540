import { SvgIcon, SvgIconProps } from '@mui/material'
import React from 'react'

export function ExpiredLoanIcon(props: SvgIconProps): JSX.Element {
  return (
    <SvgIcon viewBox="0 0 32 32" {...props}>
      <svg
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M7.09379 20H4.48962C4.05817 20 3.70837 20.3582 3.70837 20.8C3.70837 21.2418 4.05817 21.6 4.48962 21.6H7.09379C7.52525 21.6 7.87504 21.2418 7.87504 20.8C7.87504 20.3582 7.52525 20 7.09379 20Z"
          fill="#FFEBEB"
        />
        <path
          d="M4.48962 15.2H7.09379C7.52525 15.2 7.87504 14.8418 7.87504 14.4C7.87504 13.9582 7.52525 13.6 7.09379 13.6H4.48962C4.05817 13.6 3.70837 13.9582 3.70837 14.4C3.70837 14.8418 4.05817 15.2 4.48962 15.2Z"
          fill="#FFEBEB"
        />
        <path
          d="M5.27085 17.6C5.27085 17.1582 4.92106 16.8 4.4896 16.8H3.44794C3.01648 16.8 2.66669 17.1582 2.66669 17.6C2.66669 18.0418 3.01648 18.4 3.44794 18.4H4.4896C4.92106 18.4 5.27085 18.0418 5.27085 17.6Z"
          fill="#FFEBEB"
        />
        <path
          d="M19.1771 18.4C19.6086 18.4 19.9584 18.0418 19.9584 17.6C19.9584 17.1582 19.6086 16.8 19.1771 16.8C18.7457 16.8 18.3959 17.1582 18.3959 17.6C18.3959 18.0418 18.7457 18.4 19.1771 18.4Z"
          fill="#E00000"
        />
        <path
          d="M19.9583 7.23029V5.6H20.7396C21.171 5.6 21.5208 5.24181 21.5208 4.8C21.5208 4.35819 21.171 4 20.7396 4H17.6146C17.1831 4 16.8333 4.35819 16.8333 4.8C16.8333 5.24181 17.1831 5.6 17.6146 5.6H18.3958V7.23029C13.4096 7.61872 9.43016 11.6941 9.05083 16.8H7.09375C6.66229 16.8 6.3125 17.1582 6.3125 17.6C6.3125 18.0418 6.66229 18.4 7.09375 18.4H9.05042C9.44938 23.77 13.8308 28 19.1771 28C24.7863 28 29.3333 23.3437 29.3333 17.6C29.3333 12.1254 25.2024 7.63883 19.9583 7.23029V7.23029ZM19.1771 24.8C15.3001 24.8 12.1458 21.5701 12.1458 17.6C12.1458 13.6299 15.3001 10.4 19.1771 10.4C19.6085 10.4 19.9583 10.7582 19.9583 11.2C19.9583 11.6418 19.6085 12 19.1771 12C16.1616 12 13.7083 14.5122 13.7083 17.6C13.7083 20.6878 16.1616 23.2 19.1771 23.2C22.1926 23.2 24.6458 20.6878 24.6458 17.6C24.6458 16.8891 24.511 16.1834 24.252 15.5234L21.4809 17.1617C21.5067 17.3039 21.5208 17.4502 21.5208 17.6C21.5208 18.9234 20.4694 20 19.1771 20C17.8847 20 16.8333 18.9234 16.8333 17.6C16.8333 16.2766 17.8847 15.2 19.1771 15.2C19.7574 15.2 20.2888 15.4176 20.6986 15.7767L24.1949 13.7097C24.5684 13.4889 25.0458 13.6197 25.2618 14.0018C25.881 15.0975 26.2083 16.3418 26.2083 17.6C26.2083 21.5701 23.0542 24.8 19.1771 24.8V24.8Z"
          fill="#E00000"
        />
        <path
          d="M9.85866 10.3207C10.1739 9.89942 10.5177 9.49611 10.8904 9.11446C11.2631 8.7328 11.6569 8.3808 12.0683 8.05798L11.9952 7.9831C11.385 7.35824 10.3957 7.35824 9.78554 7.9831C9.17533 8.60795 9.17533 9.62102 9.78554 10.2459L9.85866 10.3207Z"
          fill="#FFEBEB"
        />
        <path
          d="M28.4951 10.3207L28.5682 10.2458C29.1784 9.62096 29.1784 8.60795 28.5682 7.9831C27.958 7.35824 26.9687 7.35824 26.3585 7.9831L26.2854 8.05798C26.6969 8.38075 27.0907 8.7328 27.4634 9.11446C27.8361 9.49611 28.1798 9.89936 28.4951 10.3207Z"
          fill="#FFEBEB"
        />
      </svg>
    </SvgIcon>
  )
}
