import { SvgIcon, SvgIconProps } from '@mui/material'
import React from 'react'

export const RegistrationIcon: React.FC<SvgIconProps> = ({
  htmlColor = 'black',
  ...props
}) => (
  <SvgIcon viewBox="0 0 32 32" htmlColor={htmlColor} {...props}>
    <path
      d="M16.0204 10.9197C13.2187 10.9197 10.9395 13.1989 10.9395 16.0005C10.9395 18.8022 13.2187 21.0814 16.0204 21.0814C18.8219 21.0814 21.1012 18.8022 21.1012 16.0005C21.1012 13.1989 18.8219 10.9197 16.0204 10.9197ZM19.037 15.0436L15.3971 18.6836C15.2443 18.8364 15.0439 18.9128 14.8437 18.9128C14.6435 18.9128 14.4433 18.8366 14.2905 18.6836L12.8995 17.2926C12.5939 16.9872 12.5939 16.4918 12.8995 16.1862C13.2049 15.8806 13.7003 15.8806 14.0059 16.1862L14.8437 17.024L17.9306 13.9373C18.236 13.6317 18.7314 13.6317 19.037 13.9373C19.3426 14.2426 19.3426 14.738 19.037 15.0436Z"
      fill="#66ACEE"
    />
    <path
      d="M27.3484 9.9021L27.3476 9.88134C27.336 9.6252 27.3282 9.3542 27.3236 9.05269C27.3018 7.58236 26.133 6.36613 24.6624 6.28394C21.5964 6.11284 19.2246 5.11308 17.198 3.13779L17.1808 3.12131C16.5197 2.51523 15.5226 2.51523 14.8614 3.12131L14.8441 3.13779C12.8176 5.11308 10.4457 6.11284 7.37975 6.28414C5.90941 6.36613 4.74039 7.58236 4.71862 9.0529C4.71414 9.35237 4.70621 9.62337 4.69461 9.88134L4.69339 9.92956C4.63378 13.056 4.55972 16.947 5.8614 20.4787C6.57714 22.4208 7.66112 24.109 9.08303 25.4968C10.7025 27.0772 12.8237 28.3318 15.3875 29.2258C15.471 29.2549 15.5576 29.2785 15.6459 29.2962C15.7704 29.321 15.8958 29.3334 16.0211 29.3334C16.1464 29.3334 16.2719 29.321 16.3962 29.2962C16.4845 29.2785 16.5718 29.2547 16.6556 29.2254C19.2165 28.3298 21.3354 27.0745 22.9534 25.4943C24.3747 24.1062 25.4587 22.4175 26.1751 20.475C27.4816 16.9327 27.4078 13.0344 27.3484 9.9021ZM16.0207 22.6462C12.3561 22.6462 9.37498 19.6651 9.37498 16.0005C9.37498 12.336 12.3561 9.35482 16.0207 9.35482C19.685 9.35482 22.6664 12.336 22.6664 16.0005C22.6664 19.6651 19.685 22.6462 16.0207 22.6462Z"
      fill="#CCE3F9"
    />
  </SvgIcon>
)
