import { styled, Theme, Box, Paper } from '@mui/material'
import React from 'react'
import { useNavigate } from 'react-router-dom'

import { ArrowRightSmallIcon, Icon, Text } from '../common/components'

export const UploadDocBanner = () => {
  const navigate = useNavigate()
  return (
    <StyledPaper
      elevation={3}
      sx={{
        width: 1,
        boxShadow: `0px 32px 48px rgba(118, 124, 119, 0.08), 0px -4px 20px rgba(0, 0, 0, 0.04)`,
      }}
    >
      <Container>
        <StyledText
          color="text.white"
          use={[
            'p',
            {
              xs: 'subheadings.16m',
              sm: 'subheadings.16m',
              md: 'subheadings.18m',
            },
          ]}
        >
          {'Get your documents ready.'}
        </StyledText>
        <StyledButton onClick={() => navigate('/documents')}>
          <ButtonText
            color="text.white"
            use={[
              'span',
              {
                xs: 'interactive.inputLabel14',
                sm: 'interactive.inputLabel14',
                md: 'interactive.buttonLabel18',
              },
            ]}
          >{`Let's upload`}</ButtonText>
          <Icon use={ArrowRightSmallIcon} />
        </StyledButton>
      </Container>
    </StyledPaper>
  )
}

const Container = styled(Box)(({ theme }: { theme: Theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '16px 24px',
  gap: '16px',
  borderRadius: 12,
  background: theme.extension.colors.base.deepBlue,
  [theme.breakpoints.down('sm')]: {
    padding: 16,
  },
}))

const StyledButton = styled('button')(({ theme }: { theme: Theme }) => ({
  display: 'flex',
  cursor: 'pointer',
  justifyContent: 'center',
  alignItems: 'center',
  marginLeft: 'auto',
  padding: '4px 32px',
  gap: '8px',
  border: `1px solid ${theme.extension.colors.text.white}`,
  borderRadius: 32,
  width: '100%',
  maxWidth: 203,
  [theme.breakpoints.down('sm')]: {
    borderRadius: 16,
    maxWidth: 143,
    padding: '4px 16px',
  },
  background: 'inherit',
  '& svg': {
    width: 20,
    height: 20,
    [theme.breakpoints.down('sm')]: {
      width: 16,
      height: 16,
    },
  },
}))

const ButtonText = styled(Text)(({ theme }: { theme: Theme }) => ({
  textTransform: 'none',
}))

const StyledPaper = styled(Paper)(({ theme }: { theme: Theme }) => ({
  marginTop: 32,
  [theme.breakpoints.down('sm')]: {
    marginTop: 24,
  },
}))

const StyledText = styled(Text)(({ theme }: { theme: Theme }) => ({
  width: '100%',
  [theme.breakpoints.down('sm')]: {
    maxWidth: 153,
  },
}))
