import { useAuth0 } from '@auth0/auth0-react'
import { Backdrop, Box, styled, Theme } from '@mui/material'
import React, { FC } from 'react'
import { useNavigate } from 'react-router-dom'

import {
  CloseIcon,
  DashboardIcon,
  DocumentIcon,
  Icon,
  LogoutIcon,
  Text,
} from '../components'

import { NewLogo } from './logo'

export type MobileHeaderProps = {
  onClose: () => void
}

export const MobileHeader: FC<MobileHeaderProps> = ({ onClose }) => {
  const navigate = useNavigate()
  const { logout } = useAuth0()
  return (
    <Backdrop
      sx={{
        background: 'rgba(0, 0, 0, 0.5)',
        zIndex: (theme) => theme.zIndex.drawer + 1,
      }}
      open
      onClick={onClose}
    >
      <Container>
        <HeaderContainer>
          <NewLogo />
          <Icon onClick={onClose} use={StyledCloseIcon} />
        </HeaderContainer>
        <ContentContainer>
          <ContentContainerItem role={'button'} onClick={() => navigate('/')}>
            <Icon use={DashboardIcon} />
            <Text
              use={['p', 'interactive.buttonLabel16']}
              color="text.softBlack"
            >
              {'Dashboard'}
            </Text>
          </ContentContainerItem>
          <ContentContainerItem
            role={'button'}
            onClick={() => navigate('/documents')}
          >
            <Icon use={DocumentIcon} />
            <Text
              use={['p', 'interactive.buttonLabel16']}
              color="text.softBlack"
            >
              {'Documents'}
            </Text>
          </ContentContainerItem>
          <ContentContainerItem
            role={'button'}
            onClick={() =>
              logout({
                returnTo: window.location.origin,
              })
            }
          >
            <Icon use={LogoutIcon} />
            <Text
              use={['p', 'interactive.buttonLabel16']}
              color="text.softBlack"
            >
              {'Log out'}
            </Text>
          </ContentContainerItem>
        </ContentContainer>
      </Container>
    </Backdrop>
  )
}

const Container = styled(Box)(({ theme }: { theme: Theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '0 16px',
  gap: '16px',
  width: 'calc(100% - 32px)',
  position: 'absolute',
  left: 0,
  top: 0,
  background: theme.extension.colors.text.white,
  borderRadius: `0 0 16px 16px`,
  zIndex: 5,
}))

const HeaderContainer = styled(Box)(({ theme }: { theme: Theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: '8px 0',
  justifyContent: 'space-between',
  gap: '57px',
  width: '100%',
  background: theme.extension.colors.text.white,
  borderBottom: `1px solid ${theme.extension.colors.divider.lightGray}`,
}))

const ContentContainer = styled(Box)(({ theme }: { theme: Theme }) => ({
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  padding: '0px 0px 16px',
  gap: '16px',
  background: theme.extension.colors.text.white,
  '& svg': {
    width: 24,
    height: 24,
  },
}))

const ContentContainerItem = styled(Box)(({ theme }: { theme: Theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: 0,
  gap: '16px',
  background: theme.extension.colors.text.white,
  width: '100%',
  flex: 'none',
}))

const StyledCloseIcon = styled(CloseIcon)(({ theme }: { theme: Theme }) => ({
  width: 24,
  height: 24,
  marginRight: '0.5rem',
}))
