import { Box, styled } from '@mui/material'
import React, { FC, forwardRef, useCallback, useRef } from 'react'
import {
  EErrorFile,
  EFileStatus,
  UploadFileResult,
} from 'src/libs/common/components/types'
import { useMobile } from 'src/libs/hooks'

import { Button, CameraIcon } from '../../../common/components'

export type UploadFileInputProps = {
  multiple: boolean
  fileSizeAllowed?: number /* file size limit in MB */
  onChange: (results: Array<UploadFileResult>) => void
  capture?: boolean | 'user' | 'environment'
}

export const UploadFileInput = forwardRef<
  HTMLInputElement,
  UploadFileInputProps
>(({ capture, fileSizeAllowed = 10, multiple, onChange }, ref) => {
  const validateFile = useCallback<(file: File) => UploadFileResult>(
    (file) => {
      const status = EFileStatus.NotStarted
      const progress = 0
      let error: UploadFileResult['error'] = undefined

      if (
        !['image/png', 'image/jpg', 'image/jpeg', 'application/pdf'].includes(
          file.type,
        )
      ) {
        error = {
          type: EErrorFile.InvalidType,
          message:
            'Upload failed, file type not supported. Use PNG, JPG, or PDF.',
        }
      } else if (file.size / 1024 / 1024 > fileSizeAllowed) {
        error = {
          type: EErrorFile.SizeExceed,
          message: 'Upload failed, file size cannot be larger than 10MB.',
        }
      }

      return { status, progress, error, data: file }
    },
    [fileSizeAllowed],
  )

  const handleChange = useCallback(
    (e) => {
      const files: FileList | null = e.target?.files
      if (files?.length) {
        const results = Array.from(files).map((file) => validateFile(file))
        onChange(results)
      }
    },
    [onChange, validateFile],
  )

  return (
    <div>
      <input
        onChange={handleChange}
        hidden
        ref={ref}
        name="file"
        accept="image/jpeg,image/jpg,image/png,application/pdf"
        multiple={multiple}
        type={'file'}
        capture={capture}
      />
    </div>
  )
})

export const CaptureAndUploadButton: FC<UploadFileInputProps> = ({
  multiple,
  onChange,
}) => {
  const isMobileDevice = useMobile()
  const uploadInputRef = useRef<HTMLInputElement>(null)

  const handleChange = (results: Array<UploadFileResult>) => {
    if (uploadInputRef.current) {
      uploadInputRef.current.value = ''
    }
    onChange(results)
  }

  return (
    <div>
      {isMobileDevice ? (
        <UploadContainer>
          <StyledButton
            icon={<CameraIcon />}
            onClick={() => uploadInputRef?.current?.click()}
            iconPosition="start"
          >
            <UploadFileInput
              ref={uploadInputRef}
              onChange={handleChange}
              multiple={multiple}
              capture={'environment'}
            />
            {'Take a photo'}
          </StyledButton>

          <StyledButton
            type="tertiary"
            onClick={() => uploadInputRef?.current?.click()}
          >
            <UploadFileInput
              ref={uploadInputRef}
              onChange={handleChange}
              multiple={multiple}
              capture={false}
            />
            {'Select existing photo'}
          </StyledButton>
        </UploadContainer>
      ) : (
        <StyledButton onClick={() => uploadInputRef?.current?.click()}>
          <UploadFileInput
            ref={uploadInputRef}
            onChange={handleChange}
            multiple={multiple}
            capture={false}
          />
          {'Upload'}
        </StyledButton>
      )}
    </div>
  )
}

const UploadContainer = styled(Box)({
  display: 'grid',
  gridAutoColumns: '1fr',
  gridTemplateRows: 'max-content',
  gap: '24px',
})

const StyledButton = styled(Button)({
  width: '100%',
})
