type Unsubscribe = () => void
export const onScrollFinish = (callback: () => void): Unsubscribe => {
  let timeoutId = -1
  const debouncedCallback = (): void => {
    clearTimeout(timeoutId)
    timeoutId = window.setTimeout(() => {
      callback()
      window.removeEventListener('scroll', debouncedCallback)
    }, 100)
  }
  window.addEventListener('scroll', debouncedCallback)
  return () => {
    window.removeEventListener('scroll', debouncedCallback)
    clearTimeout(timeoutId)
  }
}

export const useScrollIntoView = (): ((el: HTMLDivElement) => boolean) => {
  return (el) => {
    const yOffset = -16
    const rect = el.getBoundingClientRect()
    const y = rect.top + window.scrollY + yOffset
    const isInView =
      y >= window.scrollY && rect.bottom < window.scrollY + window.innerHeight

    if (isInView) {
      return false
    }
    window.scrollTo({ top: y, behavior: 'smooth' })
    return true
  }
}
