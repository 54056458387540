import React, { FC } from 'react'

type LoaderIconProps = {
  type?: 'primary' | 'secondary' | 'tertiary' | 'text' | 'card'
  width?: number
  height?: number
}

export const LoaderIconWithAnimation: FC<LoaderIconProps> = ({
  type,
  width = 32,
  height = 32,
}: LoaderIconProps) => {
  const endColor = type === 'primary' ? '#fff' : '#00C696'
  const startColor = type === 'primary' ? '#0075E3' : '#fff'
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 50 50"
    >
      <defs>
        <linearGradient
          id={`gradient-${type}`}
          x1="0%"
          y1="0%"
          x2="100%"
          y2="0%"
        >
          <stop offset="0%" stopColor={startColor} />
          <stop offset="100%" stopColor={endColor} />
        </linearGradient>
      </defs>
      <path
        fill={`url(#gradient-${type})`}
        d="M25,5A20.14,20.14,0,0,1,45,22.88a2.51,2.51,0,0,0,2.49,2.26h0A2.52,2.52,0,0,0,50,22.33a25.14,25.14,0,0,0-50,0,2.52,2.52,0,0,0,2.5,2.81h0A2.51,2.51,0,0,0,5,22.88,20.14,20.14,0,0,1,25,5Z"
      >
        <animateTransform
          attributeName="transform"
          type="rotate"
          from="0 25 25"
          to="360 25 25"
          dur="0.5s"
          repeatCount="indefinite"
        />
      </path>
    </svg>
  )
}
