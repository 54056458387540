export const getCustomerSegmentId = async ({
  customerUuid,
  token,
}: {
  customerUuid: string
  token: string
}): Promise<{ segmentUserId: string }> => {
  const response = await fetch(
    `${window._env_.REACT_APP_REFI_API_BASE_URL}/v1/customer/${customerUuid}/segment_id`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    },
  )

  if (!response.ok) {
    throw new Error(response.statusText)
  }

  return response.json()
}
