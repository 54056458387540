import { SvgIcon, SvgIconProps } from '@mui/material'
import React from 'react'

export const LicenseIcon: React.FC<SvgIconProps> = ({
  htmlColor = 'black',
  ...props
}) => (
  <SvgIcon viewBox="0 0 48 48" htmlColor={htmlColor} {...props}>
    <rect
      x="10.8574"
      y="15.8569"
      width="33.1425"
      height="22.8569"
      rx="4"
      fill="#CCE3F9"
    />
    <rect
      x="3"
      y="8"
      width="36.2854"
      height="23.7141"
      rx="5"
      fill="#66ACEE"
      stroke="white"
      strokeWidth="2"
    />
    <rect
      x="6.28613"
      y="15.8569"
      width="13.7142"
      height="12.5713"
      rx="1"
      fill="white"
    />
    <rect
      x="22.2851"
      y="15.8571"
      width="13.7142"
      height="2.28569"
      rx="1.14285"
      fill="white"
    />
    <rect
      x="6.28613"
      y="11.2856"
      width="7.99992"
      height="2.28569"
      rx="1.14285"
      fill="white"
    />
    <rect
      x="16.5713"
      y="11.2856"
      width="19.4284"
      height="2.28569"
      rx="1.14285"
      fill="white"
    />
    <rect
      x="22.2851"
      y="20.4285"
      width="13.7142"
      height="2.28569"
      rx="1.14285"
      fill="white"
    />
    <rect
      x="22.2851"
      y="25"
      width="4.57139"
      height="2.28569"
      rx="1.14285"
      fill="white"
    />
    <rect
      x="29.1426"
      y="25"
      width="6.85708"
      height="2.28569"
      rx="1.14285"
      fill="white"
    />
  </SvgIcon>
)
