import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react'
import { Box, Grid, StyledEngineProvider, ThemeProvider } from '@mui/material'
import { pipeWith } from 'pipe-ts'
import React, { useEffect, useState } from 'react'
import { Navigate, Outlet, Route, Routes } from 'react-router-dom'

import { getCustomerSegmentId } from './api'
import { NewFooter, NewHeader, theme } from './libs/common'
import { identify, TelemetrySnippets, Text } from './libs/common/components'
import { Auth0ProviderWithHistory, UserContext } from './libs/context'
import { Dashboard } from './libs/dashboard'
import { Documents } from './libs/documents'
import { withProviders } from './libs/hocs'

type RefiConsumerUser = Readonly<{
  customer_uuid?: string
  customer_uuids?: string
}>

const BaseLayout = ({
  customerUuid,
  token,
}: {
  token: string
  customerUuid: string
}) => {
  return (
    <Grid
      container
      direction="column"
      sx={{ minHeight: '100vh', maxWidth: '100vw' }}
    >
      <Grid item xs="auto">
        <NewHeader />
      </Grid>
      <Grid item xs>
        <Box sx={{ m: 'auto', p: 2, height: 1, maxWidth: '900px' }}>
          {token && customerUuid && <Outlet />}
        </Box>
      </Grid>
      <Grid item xs="auto">
        <NewFooter />
      </Grid>
    </Grid>
  )
}

const NotFound = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        height: '100%',
      }}
    >
      <Text
        use={['p', { xs: 'headings.32', md: 'headings.48' }]}
        color="base.deepGreen"
      >
        {'404'}
      </Text>
    </Box>
  )
}

function App() {
  const { getAccessTokenSilently, user } = useAuth0()
  const [token, setToken] = useState('')
  const [customerUuid, setCustomerUuid] = useState('')

  useEffect(() => {
    getAccessTokenSilently({ audience: window._env_.AUTH0_AUDIENCE })
      .then((token) => {
        setToken(token)
      })
      .catch((error) => {
        console.error('Error authorizing user: ', error)
      })
  }, [getAccessTokenSilently])

  useEffect(() => {
    let customerUuid = ''
    if (user !== undefined && token !== '') {
      const refiUser: RefiConsumerUser = user[window._env_.AUTH0_USER_JWT_CLAIM]
      const traits = { name: user?.name ?? '', email: user?.email ?? '' }

      if (refiUser.customer_uuid !== undefined) {
        customerUuid = refiUser.customer_uuid

        getCustomerSegmentId({
          customerUuid: refiUser.customer_uuid,
          token: token,
        })
          .then((customer) => {
            identify(customer.segmentUserId, traits)
          })
          .catch((error) => {
            console.error('Error getting customer segment id: ', error)
            identify('undefined', traits)
          })
      } else {
        console.error(
          'Error processing JWT claim: Missing customer_uuid field.',
          user,
        )
        customerUuid = 'undefined'
        identify(customerUuid, traits)
      }
    }
    setCustomerUuid(customerUuid)
  }, [user, token])

  return (
    <>
      <TelemetrySnippets />
      <ThemeProvider theme={theme}>
        <UserContext.Provider
          value={{
            token,
            customerUuid,
            email: user?.email ?? '',
            displayName: user?.given_name ?? user?.nickname ?? '',
          }}
        >
          <Routes>
            <Route path="*" element={<Navigate to="404" />} />
            <Route
              path="/"
              element={<BaseLayout token={token} customerUuid={customerUuid} />}
            >
              <Route path="404" element={<NotFound />} />
              <Route index element={<Dashboard />} />
              <Route path="/documents/*" element={<Documents />} />
            </Route>
          </Routes>
        </UserContext.Provider>
      </ThemeProvider>
    </>
  )
}

export default pipeWith(
  App,
  withAuthenticationRequired,
  withProviders(Auth0ProviderWithHistory, StyledEngineProvider),
)
