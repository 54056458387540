import { Box, LinearProgress, styled, Theme } from '@mui/material'
import React, { FC } from 'react'
import {
  CloseIcon,
  FileTextUpIcon,
  Icon,
  RetryIcon,
  Text,
} from 'src/libs/common/components'
import {
  EErrorFile,
  EFileStatus,
  UploadFileResult,
} from 'src/libs/common/components/types'
import { useMobile } from 'src/libs/hooks'

type UploadProgressContentProps = {
  name: string
  progress: number
  status: EFileStatus
  errorMessage?: string
}

const truncateString = (str: string, maxLength: number) => {
  const fileName = str.substring(0, str.lastIndexOf('.'))
  const ext = str.substring(str.lastIndexOf('.') + 1)
  if (fileName.length > maxLength) {
    return `${
      fileName.substr(0, maxLength - 10) +
      '...' +
      fileName.substr(str.length - 10, str.length)
    }.${ext}`
  }
  return str
}

const UploadProgressContent: FC<UploadProgressContentProps> = ({
  errorMessage,
  name,
  progress,
  status,
}) => {
  const isMobile = useMobile()

  const truncateUploadedText = (str: string) => {
    const maxLength = isMobile ? 26 : 35
    return truncateString(str, maxLength)
  }

  const truncateInProgressText = (str: string) => {
    const maxLength = isMobile ? 20 : 35
    return truncateString(str, maxLength)
  }
  return status === EFileStatus.Succeeded ? (
    <Box sx={{ width: '100%' }}>
      <ResponsiveSuccessText
        title={name}
        use={['p', 'interactive.inputLabel16']}
        color={'text.black'}
      >
        {truncateUploadedText(name)}
      </ResponsiveSuccessText>
    </Box>
  ) : (
    <UploadProgressContentContainer>
      <Box sx={{ width: '100%' }}>
        <ResponsiveProgressText
          title={name}
          use={['p', 'interactive.inputLabel16']}
          color="text.black"
        >
          {truncateInProgressText(name)}
        </ResponsiveProgressText>
      </Box>
      <Box
        sx={{
          width: '100%',
          color: (theme) =>
            errorMessage
              ? theme.extension.colors.semantic.error
              : theme.extension.colors.base.blueSky,
        }}
      >
        <LinearProgress
          variant={
            status === EFileStatus.InProgress ? 'indeterminate' : 'determinate'
          }
          value={status === EFileStatus.Failed ? 100 : progress}
          color="inherit"
        />
      </Box>
      <Box
        sx={{ width: '100%', visibility: errorMessage ? 'visible' : 'hidden' }}
      >
        <Text use={['p', 'subheadings.14m']} color="semantic.error">
          {errorMessage || '&nbsp;'}
        </Text>
      </Box>
    </UploadProgressContentContainer>
  )
}

type UploadProgressProps = {
  file: UploadFileResult
  onCancelButtonClick: (file: UploadFileResult) => void
  onRetryButtonClick: (file: UploadFileResult) => void
  progress: number
}

export const UploadProgress: FC<UploadProgressProps> = ({
  file,
  onCancelButtonClick,
  onRetryButtonClick,
  progress,
}) => {
  const canRetry =
    file.status === EFileStatus.Failed &&
    file.error?.type === EErrorFile.UploadFailed
  const canCancel =
    file.status === EFileStatus.InProgress ||
    [EErrorFile.Empty, EErrorFile.InvalidType, EErrorFile.SizeExceed].includes(
      file.error?.type as any, //https://github.com/microsoft/TypeScript/issues/26255
    )
  return (
    <Container>
      <Icon use={FileTextUpIcon} />
      <UploadProgressContent
        name={file.data.name}
        status={file.status}
        progress={progress}
        errorMessage={file.error?.message}
      />
      {canCancel && (
        <StyledIcon
          onClick={() => onCancelButtonClick(file!)}
          use={CloseIcon}
        />
      )}
      {canRetry && (
        <StyledIcon onClick={() => onRetryButtonClick(file!)} use={RetryIcon} />
      )}
    </Container>
  )
}

const UploadProgressContentContainer = styled(Box)(
  ({ theme }: { theme: Theme }) => ({
    display: 'inline-flex',
    flexDirection: 'column',
    gap: '8px',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 0,
    flex: 1,
  }),
)

const Container = styled(Box)(({ theme }: { theme: Theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  gap: '8px',
  border: `1px solid ${theme.extension.colors.divider.lightGray}`,
  borderRadius: 16,
  padding: 16,
  alignItems: 'center',
  width: 'fill-available',
  '& svg': {
    width: 20,
    height: 20,
  },
}))

const ResponsiveText = styled(Text)(({ theme }: { theme: Theme }) => ({
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
}))

const ResponsiveProgressText = styled(ResponsiveText)(
  ({ theme }: { theme: Theme }) => ({}),
)

const ResponsiveSuccessText = styled(ResponsiveText)(
  ({ theme }: { theme: Theme }) => ({}),
)

const StyledIcon = styled(Icon)(({ theme }: { theme: Theme }) => ({
  cursor: 'pointer',
}))
