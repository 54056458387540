import { makeStyles } from '@mui/styles'

const useStyles = makeStyles({
  shake: {
    animation: '$shake 500ms ease-in-out',
  },
  '@keyframes shake': {
    '0%': { transform: 'translate(0)' },
    '100%': { transform: 'translate(0)' },
    '20%': { transform: 'translate(-6px)' },
    '40%': { transform: 'translate(6px)' },
    '60%': { transform: 'translate(-6px)' },
    '80%': { transform: 'translate(6px)' },
  },
})

export const useShake = (): ((el: HTMLDivElement) => void) => {
  const styles = useStyles()
  return (el) => {
    el.classList.add(styles.shake)
    // 500 ms is how long the above animation should last
    // Removing the class so it can be used againd during submit.
    setTimeout(() => el.classList.remove(styles.shake), 500)
  }
}
