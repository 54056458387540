import { SvgIcon, SvgIconProps } from '@mui/material'
import React from 'react'

export const MenuIcon: React.FC<SvgIconProps> = ({
  htmlColor = 'black',
  ...props
}) => (
  <SvgIcon viewBox="0 0 32 32" htmlColor={htmlColor} {...props}>
    <rect width="36" height="32" rx="8" fill="white" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6 10C6 9.44772 6.44772 9 7 9H25C25.5523 9 26 9.44772 26 10C26 10.5523 25.5523 11 25 11H7C6.44772 11 6 10.5523 6 10ZM6 16C6 15.4477 6.44772 15 7 15H25C25.5523 15 26 15.4477 26 16C26 16.5523 25.5523 17 25 17H7C6.44772 17 6 16.5523 6 16ZM13 21C12.4477 21 12 21.4477 12 22C12 22.5523 12.4477 23 13 23H25C25.5523 23 26 22.5523 26 22C26 21.4477 25.5523 21 25 21H13Z"
      fill="black"
    />
  </SvgIcon>
)
