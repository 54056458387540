import { useTheme } from '@mui/material'
import React from 'react'

export const Rating = (): JSX.Element => {
  const theme = useTheme()
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 34 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M33.37 28.582c0 2.436-1.99 4.418-4.434 4.418H5.803c-2.444 0-4.433-1.982-4.433-4.418V5.418C1.37 2.982 3.359 1 5.803 1h23.076c2.444 0 4.434 1.982 4.434 4.418v23.164h.057Z"
        fill={theme.extension.colors.base.greenLeaf}
        stroke={theme.extension.colors.base.greenLeaf}
      />
      <path
        d="M11.103 10.715h2.267c.125.126.251.378.251.504l4.659 11.08h-2.77c-.126-.441-.283-.85-.44-1.26-.158-.409-.316-.818-.441-1.259h-4.91l-1.134 2.644H5.941l5.162-11.71Zm11.71 1.763h2.392v3.021h3.022v2.267h-3.022v3.022h-2.392v-3.022H19.79v-2.267h3.022v-3.021Zm-10.576 1.634L10.6 17.764h3.022l-1.385-3.652Z"
        fill={theme.extension.colors.text.white}
      />
    </svg>
  )
}
