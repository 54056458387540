import { SvgIconProps } from '@mui/material'
import React from 'react'

export const BackLicenseUploadMobileIcon: React.FC<
  Pick<SvgIconProps, 'width' | 'height'>
> = ({ height = 69, width = 122 }) => (
  <svg
    width={width}
    height={height}
    viewBox={`0 0 ${width} ${height}`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="1.5"
      y="1"
      width="98"
      height="63"
      rx="5"
      stroke="#09414E"
      strokeWidth="2"
    />
    <rect
      x="20.5"
      y="26"
      width="16"
      height="3"
      rx="1.5"
      transform="rotate(-90 20.5 26)"
      fill="#09414E"
    />
    <rect
      x="30.5"
      y="26"
      width="16"
      height="3"
      rx="1.5"
      transform="rotate(-90 30.5 26)"
      fill="#09414E"
    />
    <rect
      x="35.5"
      y="26"
      width="16"
      height="3"
      rx="1.5"
      transform="rotate(-90 35.5 26)"
      fill="#09414E"
    />
    <rect
      x="41.5"
      y="26"
      width="16"
      height="3"
      rx="1.5"
      transform="rotate(-90 41.5 26)"
      fill="#09414E"
    />
    <rect
      x="47.5"
      y="26"
      width="16"
      height="3"
      rx="1.5"
      transform="rotate(-90 47.5 26)"
      fill="#09414E"
    />
    <rect
      x="56.5"
      y="26"
      width="16"
      height="3"
      rx="1.5"
      transform="rotate(-90 56.5 26)"
      fill="#09414E"
    />
    <rect
      x="61.5"
      y="26"
      width="16"
      height="3"
      rx="1.5"
      transform="rotate(-90 61.5 26)"
      fill="#09414E"
    />
    <rect
      x="67.5"
      y="26"
      width="16"
      height="3"
      rx="1.5"
      transform="rotate(-90 67.5 26)"
      fill="#09414E"
    />
    <rect
      x="72.5"
      y="26"
      width="16"
      height="3"
      rx="1.5"
      transform="rotate(-90 72.5 26)"
      fill="#09414E"
    />
    <rect
      x="78.5"
      y="26"
      width="16"
      height="3"
      rx="1.5"
      transform="rotate(-90 78.5 26)"
      fill="#09414E"
    />
    <rect
      x="84.5"
      y="26"
      width="16"
      height="3"
      rx="1.5"
      transform="rotate(-90 84.5 26)"
      fill="#09414E"
    />
    <rect
      x="14.5"
      y="26"
      width="16"
      height="3"
      rx="1.5"
      transform="rotate(-90 14.5 26)"
      fill="#09414E"
    />
    <circle cx="99.0783" cy="46.5816" r="17.915" fill="white" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M99.08 28.6633C89.1849 28.6633 81.1633 36.6849 81.1633 46.58C81.1633 56.4751 89.1849 64.4967 99.08 64.4967C108.975 64.4967 116.997 56.4751 116.997 46.58C116.997 36.6849 108.975 28.6633 99.08 28.6633ZM99.08 37.6217C100.07 37.6217 100.872 38.4238 100.872 39.4133V44.7883H106.247C107.236 44.7883 108.038 45.5905 108.038 46.58C108.038 47.5695 107.236 48.3717 106.247 48.3717H100.872V53.7467C100.872 54.7362 100.07 55.5383 99.08 55.5383C98.0905 55.5383 97.2883 54.7362 97.2883 53.7467V48.3717H91.9133C90.9238 48.3717 90.1217 47.5695 90.1217 46.58C90.1217 45.5905 90.9238 44.7883 91.9133 44.7883H97.2883V39.4133C97.2883 38.4238 98.0905 37.6217 99.08 37.6217Z"
      fill="#0075E3"
    />
  </svg>
)
