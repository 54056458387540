import { styled, Box, Theme, SvgIconProps } from '@mui/material'
import React, { FC, ReactNode } from 'react'

import { Icon } from './icons'
import { Text } from './text'

const Container = styled(Box)(({ theme }: { theme: Theme }) => ({
  background: theme.extension.colors.text.white,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'pointer',
  gap: '8px',
  padding: 16,
  borderRadius: 8,
  boxShadow: `0px -4px 20px rgba(0, 0, 0, 0.04), 0px 32px 48px rgba(118, 124, 119, 0.08);`,
}))

const SubContainer = styled(Box)(({ theme }: { theme: Theme }) => ({
  display: 'inline-flex',
  marginRight: 'auto',
  gap: '16px',
  alignItems: 'center',
}))

const TextContainer = styled(Box)(({ theme }: { theme: Theme }) => ({
  display: 'inline-flex',
  flexDirection: 'column',
  gap: '8px',
}))

const StyledIcon = styled(Icon)(({ theme }: { theme: Theme }) => ({
  width: 24,
  height: 24,
}))

const StyledStatusText = styled(Text)(({ theme }: { theme: Theme }) => ({
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '2px 8px',
  background: '#CCE8E1',
  borderRadius: 16,
  maxWidth: 75,
  [theme.breakpoints.only('xs')]: {
    maxWidth: 62,
    padding: '2px 8px',
  },
}))

export type AccordionProps = {
  title: string
  text: string
  startIcon: FC<SvgIconProps>
  endIcon: FC<SvgIconProps>
  statusText?: ReactNode
  onClick: () => void
}

export const Accordion: FC<AccordionProps> = ({
  endIcon,
  onClick,
  startIcon,
  statusText,
  text,
  title,
}) => {
  return (
    <Container onClick={onClick} role="button">
      <SubContainer>
        <StyledIcon use={startIcon} color="text.black" />
        <TextContainer>
          <Text
            width={'100%'}
            color="text.black"
            use={['p', 'subheadings.16b']}
            margin={[0]}
          >
            {title}
          </Text>
          <Text use={['p', 'paragraphs.14']} marginTop={[0]}>
            {text}
          </Text>
          {statusText}
        </TextContainer>
      </SubContainer>
      <StyledIcon use={endIcon} color="text.black" />
    </Container>
  )
}

export const AccordionWithUploadStatus: FC<AccordionProps> = (props) => {
  return (
    <Accordion
      {...props}
      statusText={
        <StyledStatusText
          use={['p', 'brandGuide.11']}
          margin={[0]}
          color="base.deepGreen"
        >
          {'uploaded'}
        </StyledStatusText>
      }
    ></Accordion>
  )
}
