import createCache, { EmotionCache } from '@emotion/cache'
import { SxProps, Theme } from '@mui/material'
import { SystemStyleObject } from '@mui/system'
import { isArray } from 'lodash'

// prepend: true moves MUI styles to the top of the <head> so they're loaded first.
// It allows developers to easily override MUI styles with other styling solutions, like CSS modules.
export const createEmotionCache = (): EmotionCache => {
  return createCache({ key: 'css', prepend: true })
}

export type MaterialProps<T extends Theme = Theme> = {
  sx?: SxProps<T>
  className?: string
}

type TakeArray<T> = T extends Array<SystemStyleObject<Theme>>
  ? T
  : ReadonlyArray<SystemStyleObject<Theme>>
/** A utility to forward sx props
 *
 * @example
 * const MyComponent = ({sx}: {sx?: SxProps<Theme>}) => (
 *  <Box sx={[{background: 'red'}, ...sxToArray(sx)]} />
 * )
 */
export const sxToArray = (sx?: SxProps<Theme>): TakeArray<SxProps<Theme>> =>
  isArray(sx) ? sx : sx ? [sx] : []
