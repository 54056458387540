import { SvgIcon, SvgIconProps } from '@mui/material'
import React from 'react'

export const DocumentIcon: React.FC<SvgIconProps> = ({
  htmlColor = 'black',
  ...props
}) => (
  <SvgIcon viewBox="0 0 25 24" htmlColor={htmlColor} {...props}>
    <path
      d="M21.5008 6.04416C21.5008 5.17072 20.7862 4.45609 19.9128 4.45609H8.00171C7.12827 4.45609 6.41364 5.17072 6.41364 6.04416V20.8662C6.41364 21.7396 7.12827 22.4542 8.00171 22.4542H17L21.4995 18.2194L21.5008 6.04416Z"
      fill="#00C696"
    />
    <path
      d="M6.41342 6.04397C6.41342 5.17053 7.12805 4.4559 8.00149 4.4559H18.5892V3.13251C18.5892 2.25907 17.8745 1.54443 17.0011 1.54443H5.09003C4.21659 1.54443 3.50195 2.25907 3.50195 3.13251V17.9545C3.50195 18.828 4.21659 19.5426 5.09003 19.5426H6.41342V6.04397Z"
      fill="#CCF4EA"
    />
    <path
      d="M18.5881 18.2203H21.4995L17 22.4552V19.8084C17 18.935 17.7146 18.2203 18.5881 18.2203Z"
      fill="#CCF4EA"
    />
    <path
      d="M10.383 11.4708H17.5293C17.7487 11.4708 17.9263 11.2929 17.9263 11.0737C17.9263 10.8544 17.7487 10.6767 17.5293 10.6767H10.383C10.1636 10.6767 9.98596 10.8544 9.98596 11.0737C9.98596 11.2929 10.1635 11.4708 10.383 11.4708Z"
      fill="white"
    />
    <path
      d="M10.383 8.82355H17.5293C17.7487 8.82355 17.9263 8.64566 17.9263 8.42653C17.9263 8.20713 17.7487 8.02951 17.5293 8.02951H10.383C10.1636 8.02951 9.98596 8.20713 9.98596 8.42653C9.98596 8.64566 10.1635 8.82355 10.383 8.82355Z"
      fill="white"
    />
    <path
      d="M10.383 14.1177H17.5293C17.7487 14.1177 17.9263 13.9398 17.9263 13.7207C17.9263 13.5013 17.7487 13.3237 17.5293 13.3237H10.383C10.1636 13.3237 9.98596 13.5013 9.98596 13.7207C9.98596 13.9398 10.1635 14.1177 10.383 14.1177Z"
      fill="white"
    />
    <path
      d="M10.383 16.7626H14.3532C14.5726 16.7626 14.7502 16.5848 14.7502 16.3656C14.7502 16.1462 14.5726 15.9686 14.3532 15.9686H10.383C10.1636 15.9686 9.98596 16.1462 9.98596 16.3656C9.98596 16.5848 10.1635 16.7626 10.383 16.7626Z"
      fill="white"
    />
  </SvgIcon>
)
