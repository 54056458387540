import { SvgIcon, SvgIconProps } from '@mui/material'
import React from 'react'

export const ArrowRightSmallIcon: React.FC<SvgIconProps> = ({
  htmlColor = 'black',
  ...props
}) => (
  <SvgIcon viewBox="0 0 16 16" htmlColor={htmlColor} {...props}>
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.86185 2.86189C9.1222 2.60154 9.54431 2.60154 9.80466 2.86189L14.4713 7.52855C14.7317 7.7889 14.7317 8.21101 14.4713 8.47136L9.80466 13.138C9.54431 13.3984 9.1222 13.3984 8.86185 13.138C8.6015 12.8777 8.6015 12.4556 8.86185 12.1952L12.3904 8.66663H1.99992C1.63173 8.66663 1.33325 8.36815 1.33325 7.99996C1.33325 7.63177 1.63173 7.33329 1.99992 7.33329H12.3904L8.86185 3.8047C8.6015 3.54435 8.6015 3.12224 8.86185 2.86189Z"
        fill="white"
      />
    </svg>
  </SvgIcon>
)
