/**
 * Let's you define values for object while keeping keys as const.
 * Ensures typesafety of the values
 *
 * @example
 * type Values = { hi: 'hi', hello: number }
 * const x = objectWithValues<Values>()({
 *  one: { hi: 'hi', hello: 4 },
 *  two: { hi: 'hi', hello: 5 }
 * })
 * type Keys = keyof typeof x // 'one' | 'two'
 *
 * // Equivalent to this
 * const x1 = {
 *  one: { hi: 'hi', hello: 4 } as Values,
 *  two: { hi: 'hi', hello: 5 } as Values
 * }
 *
 * */
export const objectWithValues =
  <TValues>() =>
  <TKeys extends string | symbol | number>(obj: { [K in TKeys]: TValues }): {
    [K in TKeys]: TValues
  } =>
    obj
