import React, { FunctionComponent } from 'react'
import { Helmet } from 'react-helmet'

import { getSegment, getSegmentBrowserSnippet } from './get-segment'

export const TelemetrySnippets: FunctionComponent = () => {
  return (
    <div className="telemetry-snippets">
      <Helmet
        script={[
          {
            type: 'text/javascript',
            crossOrigin: 'anonymous',
            innerHTML: getSegmentBrowserSnippet(
              window._env_.REACT_APP_SEGMENT_WRITE_KEY,
            ),
          },
        ]}
      />
    </div>
  )
}

export const trackPage = (): void => {
  getSegment().page()
}

export const sendTrackEvent = (
  eventName: string,
  properties?: Record<string, unknown>,
  options?: Record<string, unknown>,
): Promise<void> => {
  return new Promise((resolve, reject) => {
    getSegment().track(eventName, properties, options, resolve)
  })
}

export const identify = <
  T extends Record<string, unknown> = Record<string, unknown>,
>(
  userId: string,
  traits: T,
): void => {
  getSegment().identify(userId, {
    ...traits,
    createdAt: new Date(Date.now()).toISOString(),
  })
}
