import { SvgIcon, SvgIconProps } from '@mui/material'
import React from 'react'

export const CameraIcon: React.FC<SvgIconProps> = ({
  htmlColor = 'black',
  ...props
}) => (
  <SvgIcon viewBox="0 0 25 24" htmlColor={htmlColor} {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.7902 2C8.99207 2 7.39571 3.15059 6.8271 4.85641C6.76345 5.04736 6.60666 5.19256 6.41139 5.24138L5.60615 5.44269C3.78064 5.89906 2.5 7.53928 2.5 9.42097V17C2.5 19.7614 4.73858 22 7.5 22H17.5C20.2614 22 22.5 19.7614 22.5 17V9.42097C22.5 7.53928 21.2194 5.89906 19.3938 5.44268L18.5886 5.24138C18.3933 5.19256 18.2365 5.04736 18.1729 4.85641C17.6043 3.15059 16.0079 2 14.2098 2H10.7902ZM12.5 11C11.3954 11 10.5 11.8954 10.5 13C10.5 14.1046 11.3954 15 12.5 15C13.6046 15 14.5 14.1046 14.5 13C14.5 11.8954 13.6046 11 12.5 11ZM8.5 13C8.5 10.7909 10.2909 9 12.5 9C14.7091 9 16.5 10.7909 16.5 13C16.5 15.2091 14.7091 17 12.5 17C10.2909 17 8.5 15.2091 8.5 13Z"
      fill="white"
    />
  </SvgIcon>
)
