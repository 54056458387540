import { Alert, AlertTitle, Box, styled } from '@mui/material'
import React, { useCallback, useContext, useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { getCustomerFiles } from 'src/api/document'
import { getRequiredCustomerUploads } from 'src/api/loan-application'
import { NAVIGATE_BACK_DELTA } from 'src/libs/constants'
import { v4 as uuidv4 } from 'uuid'

import { LoadingIcon } from '../common'
import { sendTrackEvent, Text, trackPage } from '../common/components'
import {
  CustomerFile,
  EErrorFile,
  EFileStatus,
  RequiredCustomerUpload,
  UploadFileResult,
} from '../common/components/types'
import { UserContext } from '../context'

import {
  CaptureAndUploadButton,
  ConfirmationDialog,
  DetailHeader,
  Info,
  UploadProgress,
} from './document-details/common'
import { uploadDocument } from './utils'

type UploadingFile = CustomerFile & {
  status: EFileStatus
  data: File
  error?: {
    type: EErrorFile
    message: string
  }
  controller?: AbortController
}

export const Upload: React.FC = () => {
  const user = useContext(UserContext)
  const [searchParams] = useSearchParams()
  const loanApplication = searchParams.get('loan_application') || undefined
  const stipulationType =
    parseInt(searchParams.get('stipulation_type') || '') || undefined
  const navigate = useNavigate()

  const [state, setState] = useState<{
    loading?: true
    loaded?: {
      files: CustomerFile[]
      upload: RequiredCustomerUpload
    }
    errored?: true
  }>({ loading: true })

  const [uploadingFiles, setUploadingFiles] = useState<{
    [key in string]: UploadingFile
  }>({})

  const [showDialog, setShowDialog] = useState(false)

  useEffect(() => {
    sendTrackEvent('Documents Retrieved', {
      stipId: stipulationType,
      customerId: user.customerUuid,
    })
    const controller = new AbortController()
    const customerFilesPromise = getCustomerFiles(
      user.token,
      user.customerUuid,
      controller.signal,
    )
    const requiredCustomerUploadsPromise =
      loanApplication !== undefined
        ? getRequiredCustomerUploads(
            user.token,
            loanApplication,
            controller.signal,
          )
        : Promise.resolve([])
    Promise.all([customerFilesPromise, requiredCustomerUploadsPromise])
      .then(([files, uploads]) => {
        files = files.filter(
          (f) =>
            f.loan_application === loanApplication &&
            f.stipulation_type === stipulationType,
        )
        const upload = uploads.find(
          (u) => u.stipulation_type === stipulationType,
        )
        if (upload === undefined) {
          throw Error('There is no matching required customer upload.')
        }
        setState({ loaded: { files, upload } })
      })
      .catch((error) => {
        if (!controller.signal.aborted) {
          console.error('Error loading documents: ', error)
          setState({ errored: true })
        }
      })
    return () => controller.abort()
  }, [user, loanApplication, stipulationType])

  const startUpload = useCallback(
    async (id: string, file: UploadingFile) => {
      try {
        if (file?.error) {
          return
        }
        file.controller = new AbortController()
        setUploadingFiles((prev) => ({
          ...prev,
          [file.uuid]: { ...file, status: EFileStatus.InProgress },
        }))
        await uploadDocument(
          user,
          file.data,
          file.controller.signal,
          loanApplication,
          stipulationType,
        )
        setUploadingFiles((prev) => ({
          ...prev,
          [file.uuid]: { ...file, status: EFileStatus.Succeeded },
        }))
        sendTrackEvent('Documents Upload Succeeded', {
          stipId: stipulationType,
          fileName: file.data.name,
        })
      } catch (error) {
        if (error.name !== 'AbortError') {
          setUploadingFiles((prev) => ({
            ...prev,
            [file.uuid]: {
              ...file,
              status: EFileStatus.Failed,
              error: {
                type: EErrorFile.UploadFailed,
                message: 'Upload failed, please try again.',
              },
            },
          }))
          sendTrackEvent('Documents Upload Failed', {
            stipId: stipulationType,
            error: {
              type: EErrorFile.UploadFailed,
              message: 'Upload failed, please try again.',
            },
            fileName: file.data.name,
          })
        }
      }
    },
    [user, loanApplication, stipulationType],
  )

  const handleUpload = useCallback(
    (files: UploadFileResult[]) => {
      const transformed = files.map<UploadingFile>((f) => ({
        uuid: uuidv4(),
        name: f.data!.name,
        loanApplication,
        stipulationType,
        uploaded_at: Date.now().toString(),
        status: EFileStatus.NotStarted,
        data: f.data!,
        error: f.error,
      }))
      setUploadingFiles((prev) => {
        const next = { ...prev }
        transformed.forEach((f) => (next[f.uuid] = f))
        return next
      })
      transformed.forEach((f) => startUpload(f.uuid, f))
    },
    [startUpload, loanApplication, stipulationType],
  )

  const renderUploadCard = useCallback(
    (file: UploadingFile) => (
      <UploadCard
        key={file.uuid}
        sx={{
          cursor:
            file.status === EFileStatus.InProgress ? 'pointer' : 'default',
        }}
      >
        <UploadComponent>
          <UploadProgress
            file={file}
            progress={0}
            onCancelButtonClick={() => {
              if (file.status === EFileStatus.InProgress) {
                file.controller?.abort()
              }
              setUploadingFiles((prev) => {
                const next = { ...prev }
                delete next[file.uuid]
                return next
              })
            }}
            onRetryButtonClick={() => {
              if (file.status === EFileStatus.InProgress) {
                file.controller?.abort()
              }
              startUpload(file.uuid, file)
            }}
          />
        </UploadComponent>
      </UploadCard>
    ),
    [setUploadingFiles, startUpload],
  )

  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
    })
  }, [])

  useEffect(() => {
    trackPage()
  }, [])

  return (
    <Container>
      <DetailHeader
        title={state.loaded?.upload?.title}
        subTitle={state.loaded?.upload?.description}
        onBackButtonClick={() =>
          Object.values(uploadingFiles).some(
            (file) => file?.status === EFileStatus.InProgress,
          )
            ? setShowDialog(true)
            : navigate(NAVIGATE_BACK_DELTA)
        }
      />
      {state.loading ? (
        <LoadingContainer>
          <LoadingIcon sx={{ fontSize: '40px' }} />
          <Text use={['p', 'paragraphs.16']}>
            {`We're pulling up your documents...`}
          </Text>
        </LoadingContainer>
      ) : state.loaded ? (
        <>
          <UploadContainer>
            {state.loaded.files.map((file) =>
              renderUploadCard({
                ...file,
                status: EFileStatus.Succeeded,
                data: { name: file.name } as File, //TODO: Hack from original code
              }),
            )}
            {Object.values(uploadingFiles).map((file) =>
              renderUploadCard(file),
            )}
          </UploadContainer>
          <ButtonContainer>
            <StyledInfoDetails />
            <CaptureAndUploadButton
              multiple
              onChange={(results) => handleUpload(results)}
            />
          </ButtonContainer>
        </>
      ) : (
        <Alert severity="error">
          <AlertTitle>Error loading documents.</AlertTitle>
          It&apos;s not you, it&apos;s us.
          <br />
          Call 1-877-445-0070 for assistance or try again later.
        </Alert>
      )}
      <ConfirmationDialog
        open={showDialog}
        onStayBtnClick={() => setShowDialog(false)}
        onLeaveBtnClick={() => navigate(NAVIGATE_BACK_DELTA)}
      />
    </Container>
  )
}

const StyledInfoDetails = styled(Info)(() => ({
  marginRight: 'auto',
}))

const ButtonContainer = styled(Box)(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: '1fr 263px',
  justifyContent: 'space-between',
  gap: '16px',
  marginTop: '16px',
  [theme.breakpoints.only('xs')]: {
    marginTop: '8px',
    gap: '24px',
    gridTemplateColumns: '100%',
  },
}))

const UploadCard = styled(Box)(() => ({
  display: 'grid',
  gridTemplateColumns: '100%',
  gap: '8px',
  padding: 0,
  cursor: 'pointer',
}))

const UploadComponent = styled(Box)(({ theme }) => ({
  display: 'inline-grid',
  gridTemplateColumns: '100%',
  padding: 0,
  background: theme.extension.colors.text.white,
  borderRadius: 16,
  WebkitTapHighlightColor: 'transparent',
}))

const UploadContainer = styled(Box)(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: '100%',
  gap: '16px',
  background: theme.extension.colors.text.white,
  padding: 0,
  marginTop: 0,
  [theme.breakpoints.only('xs')]: {
    marginTop: 8,
  },
}))

const LoadingContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  marginTop: 48,
  [theme.breakpoints.down('sm')]: {
    marginTop: 32,
  },
}))

const Container = styled(Box)(() => ({
  display: 'grid',
  gridTemplateColumns: '1fr',
  gap: '16px',
}))
