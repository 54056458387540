import { Box, Grid, Link, styled, Theme, useMediaQuery } from '@mui/material'
import { createStyles, makeStyles } from '@mui/styles'
import cx from 'classnames'
import React, { useRef, useState } from 'react'

import { CloseIcon, Icon, MenuIcon } from '../components'

import { DesktopHeader } from './desktop-header'
import { NewLogo } from './logo'
import { MobileHeader } from './mobile-header'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    menu: { zIndex: 1 },
    container: {
      background: theme.palette.common.white,
      borderBottom: `solid 1px ${theme.extension.colors.divider.lightGray}`,
      padding: theme.spacing(1, 5),
      marginBottom: theme.spacing(5),
      overflow: 'hidden',

      [theme.breakpoints.down('sm')]: {
        marginBottom: theme.spacing(4),
        padding: theme.spacing(1, 2),
        borderBottom: `none`,
        boxShadow: `0 -5px 55px ${theme.extension.colors.divider.lightGray}`,
      },
    },
    logoLink: {
      lineHeight: '0',
    },
    logoContainer: {
      paddingBottom: theme.spacing(1),
      [theme.breakpoints.down('sm')]: {
        paddingBottom: theme.spacing(0),
      },
    },
    status: {
      justifyContent: 'flex-end',
    },
  }),
)

export const NewHeader = (): JSX.Element => {
  const classes = useStyles()
  const isPhone = useMediaQuery((theme: Theme) => theme.breakpoints.only('xs'))
  const [showHeader, setShowHeader] = useState(false)
  const menuIconRef = useRef(null)

  return (
    <Grid
      container
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      className={cx(classes.container)}
    >
      <Grid item xs container>
        <Link
          href="https://www.caribou.com/"
          className={cx(classes.logoLink)}
          target="_blank"
        >
          <div className={cx(classes.logoContainer)}>
            <NewLogo mobile={isPhone} />
          </div>
        </Link>
      </Grid>
      <Grid item xs container className={cx(classes.status)}>
        <Grid>
          {showHeader ? (
            isPhone ? (
              <MobileHeader onClose={() => setShowHeader(false)} />
            ) : (
              <DesktopHeader
                element={menuIconRef?.current}
                onClose={() => setShowHeader(false)}
              />
            )
          ) : null}
          <Box
            ref={menuIconRef}
            sx={{ cursor: 'pointer', zIndex: 10 }}
            onClick={() => setShowHeader(true)}
          >
            <StyledIcon use={showHeader ? CloseIcon : MenuIcon} />
          </Box>
        </Grid>
      </Grid>
    </Grid>
  )
}

const StyledIcon = styled(Icon)({
  cursor: 'pointer',
})
