import { Box, styled, SvgIconProps, Theme } from '@mui/material'
import { NotVoid } from 'lodash'
import React, { FC, useRef } from 'react'
import { Text, Icon } from 'src/libs/common/components'
import { UploadFileResult } from 'src/libs/common/components/types'

import { UploadFileInput } from '../common/upload-file'

const UploadCard = styled(Box)(({ theme }: { theme: Theme }) => ({
  display: 'grid',
  gridTemplateColumns: '1fr',
  gap: '8px',
  padding: 0,
}))

const UploadComponent = styled(Box)(({ theme }: { theme: Theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: 16,
  background: theme.extension.colors.text.white,
  border: `2px dashed ${theme.extension.colors.base.blueSky}`,
  borderRadius: 16,
  cursor: 'pointer',
  outline: 'none',
  WebkitTapHighlightColor: 'transparent',
}))

type LicenseUploadProps = {
  icon: FC<SvgIconProps>
  title: string
  onLicenseUpload: (results: Array<UploadFileResult>) => NotVoid
}

export const LicenseUpload: FC<LicenseUploadProps> = ({
  icon,
  onLicenseUpload,
  title,
}) => {
  const licenseInputRef = useRef<HTMLInputElement>(null)

  return (
    <UploadCard>
      <Text
        use={['p', { xs: 'subheadings.14b', sm: 'subheadings.16b' }]}
        color="text.black"
      >
        {title}
      </Text>
      <UploadComponent
        role={'button'}
        onClick={() => licenseInputRef?.current?.click()}
      >
        <UploadFileInput
          ref={licenseInputRef}
          multiple={false}
          onChange={onLicenseUpload}
        />
        <Icon use={icon} />
      </UploadComponent>
    </UploadCard>
  )
}
