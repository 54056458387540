import {
  Alert,
  AlertTitle,
  Box,
  styled,
  SvgIconProps,
  Theme,
} from '@mui/material'
import { keys, isEmpty } from 'lodash'
import React, { useCallback, useContext, useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { getCustomerFiles } from 'src/api/document'
import {
  BackLicenseUploadIcon,
  BackLicenseUploadMobileIcon,
  FrontLicenseUploadIcon,
  FrontLicenseUploadMobileIcon,
  Icon,
  LoadingIcon,
  PlusIcon,
  sendTrackEvent,
  Text,
  trackPage,
} from 'src/libs/common/components'
import {
  DefaultStipulationTypes,
  EErrorFile,
  EFileStatus,
  UploadFileResult,
  UploadProgressFile,
} from 'src/libs/common/components/types'
import { NAVIGATE_BACK_DELTA } from 'src/libs/constants'
import { FileUploadContext, UserContext } from 'src/libs/context'
import { useMobile } from 'src/libs/hooks'
import { v4 as uuidv4 } from 'uuid'

import {
  createPairOfLicenseForRepresentation,
  isAnyFileUploading,
  prepareCustomerFilesForDetailsView,
  setFileProgress,
  uploadDocument,
} from '../../utils'
import {
  Info,
  DetailHeader,
  UploadProgress,
  ConfirmationDialog,
} from '../common'

import { LicenseUpload } from './license-upload'

const Container = styled(Box)(({ theme }: { theme: Theme }) => ({
  display: 'grid',
  gridTemplateColumns: '1fr',
  gap: '16px',
}))

const UploadContainer = styled(Box)(({ theme }: { theme: Theme }) => ({
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  gap: '32px',
  background: theme.extension.colors.text.white,
  padding: 24,
  borderRadius: 16,
  boxShadow: `0px -4px 20px rgba(0, 0, 0, 0.04), 0px 32px 48px rgba(99, 99, 99, 0.08)`,
  [theme.breakpoints.only('xs')]: {
    gridTemplateColumns: '1fr',
    gridTemplateRows: 'max-content',
    gap: '24px',
    padding: 16,
  },
}))

export type PreUploadFile = {
  [key: string]: UploadFileResult[]
}

export const License = () => {
  const [searchParams] = useSearchParams()
  const loanApplication = searchParams.get('loan_application') || undefined
  const [preUploadedFileForFrontLicense, setPreUploadedFileForFrontLicense] =
    useState<PreUploadFile>({})

  const [
    fileUploadProgressForFrontLicense,
    setFileUploadProgressForFrontLicense,
  ] = useState<UploadProgressFile>({})
  const [rows, setRows] = useState<string[]>([])

  const [
    fileUploadProgressForBackLicense,
    setFileUploadProgressForBackLicense,
  ] = useState<UploadProgressFile>({})

  const [preUploadedFileForBackLicense, setPreUploadedFileForBackLicense] =
    useState<PreUploadFile>({})

  const isMobile = useMobile()
  const navigate = useNavigate()
  const [showDialog, setShowDialog] = useState(false)

  const user = useContext(UserContext)

  const [state, setState] = useState<{
    loading?: true
    loaded?: true
    errored?: true
  }>({ loading: true })

  useEffect(() => {
    const controller = new AbortController()
    sendTrackEvent('Documents Retrieved', {
      stipType: 'License',
      customerId: user.customerUuid,
    })
    getCustomerFiles(user.token, user.customerUuid, controller.signal)
      .then((files) => {
        files = files.filter((f) => f.loan_application === loanApplication)
        const frontLicenseFiles = files.filter(
          (f) =>
            f.stipulation_type ===
            DefaultStipulationTypes.DRIVERS_LICENSE_FRONT,
        )
        const backLicenseFiles = files.filter(
          (f) =>
            f.stipulation_type === DefaultStipulationTypes.DRIVERS_LICENSE_BACK,
        )
        const { backLicenses, frontLicenses, license } =
          createPairOfLicenseForRepresentation(
            frontLicenseFiles,
            backLicenseFiles,
          )
        const mappedFilesForFrontLicenses =
          prepareCustomerFilesForDetailsView(frontLicenses)
        const mappedFilesForBackLicenses =
          prepareCustomerFilesForDetailsView(backLicenses)
        setFileUploadProgressForFrontLicense(mappedFilesForFrontLicenses)
        setFileUploadProgressForBackLicense(mappedFilesForBackLicenses)
        setPreUploadedFileForFrontLicense(
          Object.fromEntries(
            Object.entries(mappedFilesForFrontLicenses).map(([key, value]) => {
              return [key, [value.file]]
            }),
          ),
        )
        setPreUploadedFileForBackLicense(
          Object.fromEntries(
            Object.entries(mappedFilesForBackLicenses).map(([key, value]) => {
              return [key, [value.file]]
            }),
          ),
        )
        setRows(!isEmpty(license) ? keys(license) : [uuidv4()])
        setState({ loaded: true })
      })
      .catch((error) => {
        if (!controller.signal.aborted) {
          console.error('Error loading documents: ', error)
          setState({ errored: true })
        }
      })
    return () => controller.abort()
  }, [user, loanApplication])

  const startUploadForLicense = useCallback(
    async (
      id: string,
      file: UploadFileResult,
      stipulationType: number,
      setFileUploadProgress: React.Dispatch<
        React.SetStateAction<UploadProgressFile>
      >,
    ) => {
      try {
        if (file.error) {
          return setFileUploadProgress(
            (fileUploadProgress: UploadProgressFile) =>
              setFileProgress({
                id,
                file,
                fileUploadProgress,
                status: file.status,
                error: file.error,
              }),
          )
        }
        const controller = new AbortController()
        setFileUploadProgress((fileUploadProgress: UploadProgressFile) =>
          setFileProgress({ id, file, fileUploadProgress, controller }),
        )
        await uploadDocument(
          user,
          file.data,
          controller.signal,
          loanApplication,
          stipulationType,
        )
        setFileUploadProgress((fileUploadProgress: UploadProgressFile) =>
          setFileProgress({
            id,
            file,
            fileUploadProgress,
            status: EFileStatus.Succeeded,
          }),
        )
        sendTrackEvent('Documents Upload Succeeded', {
          stipId: stipulationType,
          fileName: file.data.name,
        })
      } catch (error) {
        if (error.name !== 'AbortError') {
          sendTrackEvent('Documents Upload Failed', {
            stipId: stipulationType,
            error: {
              type: EErrorFile.UploadFailed,
              message: 'Upload failed, please try again.',
            },
            fileName: file.data.name,
          })
          setFileUploadProgress((fileUploadProgress: UploadProgressFile) =>
            setFileProgress({
              id,
              file,
              fileUploadProgress,
              status: EFileStatus.Failed,
              error: {
                type: EErrorFile.UploadFailed,
                message: 'Upload failed, please try again.',
              },
            }),
          )
        }
      }
    },
    [user, loanApplication],
  )

  const handleLicenseUpload = useCallback(
    (
      key: string,
      results: UploadFileResult[],
      stipulationType: number,
      setPreUploadedFile: React.Dispatch<React.SetStateAction<PreUploadFile>>,
      setFileUploadProgress: React.Dispatch<
        React.SetStateAction<UploadProgressFile>
      >,
    ) => {
      setPreUploadedFile((prevResult) => ({
        ...prevResult,
        [key]: results,
      }))
      startUploadForLicense(
        key,
        results[0], //what about others?
        stipulationType,
        setFileUploadProgress,
      )
    },
    [startUploadForLicense],
  )

  const handleOnCancelButtonClick = useCallback(
    (
      key: string,
      preUploadedFile: PreUploadFile,
      fileUploadProgress: UploadProgressFile,
      setPreUploadedFile: React.Dispatch<React.SetStateAction<PreUploadFile>>,
      setFileUploadProgress: React.Dispatch<
        React.SetStateAction<UploadProgressFile>
      >,
    ) => {
      const { controller, file } = fileUploadProgress[key]
      if (file.status === EFileStatus.InProgress) {
        controller?.abort()
        setFileUploadProgress((prevState) => {
          const state = { ...prevState }
          delete state[key]
          return state
        })
      }
      setPreUploadedFile((prevState) => {
        const state = { ...prevState }
        delete state[key]
        return state
      })
    },
    [],
  )

  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
    })
  }, [])

  useEffect(() => {
    trackPage()
    sendTrackEvent('Documents', {
      stipType: 'License',
    })
  }, [])

  const getLicenseElements = useCallback(
    (
      id: string,
      type: 'Front' | 'Back',
      stipulationType: number,
      preUploadedFile: PreUploadFile,
      fileUploadProgress: UploadProgressFile,
      setPreUploadedFile: React.Dispatch<React.SetStateAction<PreUploadFile>>,
      setFileUploadProgress: React.Dispatch<
        React.SetStateAction<UploadProgressFile>
      >,
      uploadIcon: React.FC<SvgIconProps>,
      mobileUploadIcon: React.FC<SvgIconProps>,
    ) => {
      const isUploading =
        fileUploadProgress?.[id]?.file?.status === EFileStatus.InProgress
      const handleOnCancelButtonClickForType = () => {
        handleOnCancelButtonClick(
          id,
          preUploadedFile,
          fileUploadProgress,
          setPreUploadedFile,
          setFileUploadProgress,
        )
      }
      const handleLicenseUploadForFiles = (files: UploadFileResult[]) => {
        handleLicenseUpload(
          id,
          files,
          stipulationType,
          setPreUploadedFile,
          setFileUploadProgress,
        )
      }
      return preUploadedFile[id] ? (
        <FileUploadContext.Provider
          key={`${id}_${type.toLowerCase()}`}
          value={{
            ...fileUploadProgress?.[id],
            onCancelButtonClick: handleOnCancelButtonClickForType,
            onRetryButtonClick: (file: UploadFileResult) => {
              handleOnCancelButtonClickForType()
              handleLicenseUploadForFiles([file])
            },
          }}
        >
          <UploadCard
            key={`${id}_card_${type.toLowerCase()}`}
            sx={{
              cursor: isUploading ? 'pointer' : 'default',
            }}
          >
            <Text
              use={['p', { xs: 'subheadings.14b', sm: 'subheadings.16b' }]}
              color="text.black"
            >
              {`${type} of License`}
            </Text>
            <UploadComponent>
              <UploadProgress
                file={fileUploadProgress[id]!.file}
                progress={fileUploadProgress[id]!.progress}
                onCancelButtonClick={handleOnCancelButtonClickForType}
                onRetryButtonClick={(file: UploadFileResult) => {
                  handleOnCancelButtonClickForType()
                  handleLicenseUploadForFiles([file])
                }}
              />
            </UploadComponent>
          </UploadCard>
        </FileUploadContext.Provider>
      ) : (
        <LicenseUpload
          key={`${id}_upload_${type.toLowerCase()}`}
          title={`${type} of License`}
          icon={isMobile ? mobileUploadIcon : uploadIcon}
          onLicenseUpload={(results) => handleLicenseUploadForFiles(results)}
        />
      )
    },
    [handleLicenseUpload, handleOnCancelButtonClick, isMobile],
  )

  const isFileUploading =
    isAnyFileUploading(fileUploadProgressForFrontLicense) ||
    isAnyFileUploading(fileUploadProgressForBackLicense)

  return (
    <Container>
      <DetailHeader
        onBackButtonClick={() =>
          isFileUploading ? setShowDialog(true) : navigate(NAVIGATE_BACK_DELTA)
        }
        title={"Driver's License"}
        subTitle={
          isMobile
            ? `Please take a photo of the front and back of your license. Make sure to turn off the flash on your camera and include all four corners.`
            : `Please upload photos of the front and back of your license. Make sure to avoid glare on the photos and include all four corners.`
        }
      />
      {state.loading ? (
        <LoadingContainer>
          <LoadingIcon sx={{ fontSize: '40px' }} />
          <Text use={['p', 'paragraphs.16']}>
            {`We're pulling up your documents...`}
          </Text>
        </LoadingContainer>
      ) : state.loaded ? (
        <>
          <Info sx={{ marginTop: 1, marginBottom: 1 }} />
          {rows.map((id, idx) => {
            return (
              <>
                <Text
                  key={id + '_text'}
                  use={['p', { xs: 'subheadings.14b', sm: 'subheadings.16b' }]}
                >
                  {"Driver's License " + (idx + 1)}
                </Text>
                <UploadContainer key={`${id}_container`}>
                  {getLicenseElements(
                    id,
                    'Front',
                    DefaultStipulationTypes.DRIVERS_LICENSE_FRONT,
                    preUploadedFileForFrontLicense,
                    fileUploadProgressForFrontLicense,
                    setPreUploadedFileForFrontLicense,
                    setFileUploadProgressForFrontLicense,
                    FrontLicenseUploadIcon,
                    FrontLicenseUploadMobileIcon,
                  )}
                  {getLicenseElements(
                    id,
                    'Back',
                    DefaultStipulationTypes.DRIVERS_LICENSE_BACK,
                    preUploadedFileForBackLicense,
                    fileUploadProgressForBackLicense,
                    setPreUploadedFileForBackLicense,
                    setFileUploadProgressForBackLicense,
                    BackLicenseUploadIcon,
                    BackLicenseUploadMobileIcon,
                  )}
                </UploadContainer>
              </>
            )
          })}
          <Box>
            <AddMoreButton
              role={'button'}
              onClick={() => {
                setRows([...rows, uuidv4()])
              }}
            >
              <Icon use={PlusIcon} />
              <Text use={['p', 'interactive.buttonLabel16']}>
                {'Add another license'}
              </Text>
            </AddMoreButton>
          </Box>
        </>
      ) : (
        <Alert severity="error">
          <AlertTitle>Error loading documents.</AlertTitle>
          It&apos;s not you, it&apos;s us.
          <br />
          Call 1-877-445-0070 for assistance or try again later.
        </Alert>
      )}
      <ConfirmationDialog
        open={showDialog}
        onLeaveBtnClick={() => navigate(NAVIGATE_BACK_DELTA)}
        onStayBtnClick={() => setShowDialog(false)}
      />
    </Container>
  )
}

const AddMoreButton = styled(Box)(({ theme }: { theme: Theme }) => ({
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
  padding: 0,
  gap: '16px',
  flexGrow: 0,
  order: 0,
  flex: 'none',
  cursor: 'pointer',
  '& svg': {
    width: 24,
    height: 24,
  },
}))

const UploadCard = styled(Box)(({ theme }: { theme: Theme }) => ({
  display: 'grid',
  gridTemplateColumns: '1fr',
  gap: '8px',
  padding: 0,
  cursor: 'pointer',
}))

const UploadComponent = styled(Box)(({ theme }: { theme: Theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  padding: 0,
  background: theme.extension.colors.text.white,
  borderRadius: 16,
}))

const LoadingContainer = styled(Box)(({ theme }: { theme: Theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  marginTop: 48,
  [theme.breakpoints.down('sm')]: {
    marginTop: 32,
  },
}))
