import { styled, Box, Theme, useMediaQuery, useTheme } from '@mui/material'
import React from 'react'

import { Text } from '../common/components'

const Container = styled(Box)(({ theme }: { theme: Theme }) => ({
  background: `linear-gradient(0deg, rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0.15)), radial-gradient(151.41% 260.9% at 18.53% -32.1%, #09414E 19.28%, #0E4A58 19.4%, #0E4A58 29.33%, #0D5262 29.51%, #09414E 45.7%, #0D5262 45.83%) `,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '8px',
  marginBottom: 8,
  padding: theme.spacing(3),
  [theme.breakpoints.down('sm')]: {
    gap: '8px',
    padding: theme.spacing(2),
  },
  borderRadius: theme.spacing(2),
}))

export const DocumentHeader = ({ displayName }: { displayName: string }) => {
  const theme: Theme = useTheme()
  const isMobileDevice = useMediaQuery(theme.breakpoints.down('sm'))
  return (
    <>
      <Text
        use={['p', 'uppercase.16sb']}
        sx={{
          fontSize: { sm: 18 },
        }}
        marginBottom={2}
        color="base.greenLeaf"
      >
        Hi {displayName}
      </Text>
      <Container>
        <Text
          width={'100%'}
          use={['p', { xs: 'subheadings.24b', md: 'headings.32' }]}
          color="text.white"
        >
          {"Let's get your documents uploaded!"}
        </Text>
        <Text width={'100%'} use={['p', 'subheadings.16m']} color="text.white">
          {`Start by selecting a document type and ${
            isMobileDevice
              ? 'simply take a photo or select an existing one.'
              : 'upload existing files.'
          }`}
        </Text>
      </Container>
    </>
  )
}
