import { RequiredCustomerUpload } from '../libs/common/components/types'

export type ExpandedLoanApplication = {
  loanApplication: {
    uuid: string
    simplifiedStatus: 'inProgress' | 'funded' | 'expired'
    createdAt: Date
    updatedAt: Date
    fundedAt?: Date
    expiredAt?: Date
  }
  vehicle: {
    uuid: string
    year?: number
    make?: string
    model?: string
  }
}

export async function getExpandedLoanApplications(
  accessToken: string,
  customerUuid: string,
  signal: AbortSignal,
): Promise<ExpandedLoanApplication[]> {
  const response = await fetch(
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    `${window._env_.REACT_APP_CONSUMER_AGGREGATOR_BASE_URL}/v1/get-expanded-loan-applications?customerUuid=${customerUuid}`,
    {
      headers: {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + accessToken,
      },
      signal: signal,
    },
  )

  if (!response.ok) {
    throw new Error(response.statusText)
  }

  const json: {
    loanApplication: {
      uuid: string
      simplifiedStatus: 'inProgress' | 'funded' | 'expired'
      createdAt: string
      updatedAt: string
      fundedAt?: string
      expiredAt?: string
    }
    vehicle?: {
      uuid: string
      year?: number
      make?: string
      model?: string
    }
  }[] = await response.json()

  return json
    .filter((expandedLoanApplication) => {
      //exclude loan applications without a PartnerLeadSource (missing vehicle information and cannot be resumed)
      return expandedLoanApplication.vehicle !== undefined
    })
    .map((expandedLoanApplication) => {
      const loan = expandedLoanApplication.loanApplication
      return {
        loanApplication: {
          uuid: loan.uuid,
          simplifiedStatus: loan.simplifiedStatus,
          createdAt: new Date(loan.createdAt),
          updatedAt: new Date(loan.updatedAt),
          fundedAt: loan.fundedAt ? new Date(loan.fundedAt) : undefined,
          expiredAt: loan.expiredAt ? new Date(loan.expiredAt) : undefined,
        },
        vehicle: expandedLoanApplication.vehicle!,
      }
    })
}

export type NewLoanApplication = {
  loanApplicationUuid: string
}

export async function newLoanApplication(
  accessToken: string,
  customerUuid: string,
): Promise<NewLoanApplication> {
  const response = await fetch(
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    `${window._env_.REACT_APP_CONSUMER_AGGREGATOR_BASE_URL}/v1/new-loan-application`,
    {
      method: 'POST',
      headers: {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + accessToken,
      },
      body: JSON.stringify({ customerUuid }),
    },
  )

  if (!response.ok) {
    throw new Error(response.statusText)
  }

  return (await response.json()) as NewLoanApplication
}

export type GetLoanApplicationResume = {
  resumeUrl: string
}

export async function getLoanApplicationResume(
  accessToken: string,
  loanApplicationUuid: string,
): Promise<GetLoanApplicationResume> {
  const response = await fetch(
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    `${window._env_.REACT_APP_CONSUMER_AGGREGATOR_BASE_URL}/v1/${loanApplicationUuid}/la-resume`,
    {
      headers: {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + accessToken,
      },
    },
  )

  if (!response.ok) {
    throw new Error(response.statusText)
  }

  const json: {
    resumeUrl: string
  } = await response.json()

  return {
    // the new flow doesn't have a proper dev environment, so it returns prod links in dev
    // this replaces the domain with the existing environment variable set to Vercel deploys in dev
    resumeUrl: `${
      window._env_.REACT_APP_CONSUMER_FLOW_BASE_URL
    }${json.resumeUrl.substring(json.resumeUrl.indexOf('/resume'))}`,
  }
}

export async function getRequiredCustomerUploads(
  accessToken: string,
  loanApplicationUuid: string,
  signal: AbortSignal,
): Promise<RequiredCustomerUpload[]> {
  const response = await fetch(
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    `${window._env_.REACT_APP_REFI_API_BASE_URL}/v2/loan_application/${loanApplicationUuid}/required_customer_uploads`,
    {
      headers: {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + accessToken,
      },
      signal,
    },
  )

  if (!response.ok) {
    throw new Error(response.statusText)
  }

  const json: {
    data: RequiredCustomerUpload[]
  } = await response.json()

  return json.data
}
