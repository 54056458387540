import React from 'react'
import { Route, Routes } from 'react-router-dom'

import {
  License,
  Miscellaneous,
  Odometer,
  OriginalFinanceContract,
  Insurance,
  Registration,
} from './document-details'
import { DocumentsPage } from './documents'
import { Upload } from './upload'

export const Documents = () => {
  return (
    <Routes>
      <Route index element={<DocumentsPage />} />
      <Route path="/license" element={<License />} />
      <Route path="/registration" element={<Registration />} />
      <Route path="/insurance" element={<Insurance />} />
      <Route path="/odometer" element={<Odometer />} />
      <Route path="/finance-contract" element={<OriginalFinanceContract />} />
      <Route path="/misc" element={<Miscellaneous />} />
      <Route path="/upload" element={<Upload />} />
    </Routes>
  )
}
