import { SvgIcon, SvgIconProps } from '@mui/material'
import React from 'react'

export const OdometerIcon: React.FC<SvgIconProps> = ({
  htmlColor = 'black',
  ...props
}) => (
  <SvgIcon viewBox="0 0 32 32" htmlColor={htmlColor} {...props}>
    <path
      d="M22.7739 16.0001C22.7739 15.5686 23.1236 15.2188 23.5551 15.2188H25.2003C24.824 10.7493 21.2507 7.17605 16.7813 6.7998V8.44501C16.7813 8.87647 16.4315 9.22626 16 9.22626C15.5685 9.22626 15.2188 8.87647 15.2188 8.44501V6.7998C10.7493 7.17605 7.17599 10.7493 6.79974 15.2188H8.4449C8.87636 15.2188 9.22615 15.5686 9.22615 16.0001C9.22615 16.4315 8.87636 16.7813 8.4449 16.7813H6.79974C7.17599 21.2508 10.7493 24.8241 15.2188 25.2003V23.5551C15.2188 23.1237 15.5685 22.7739 16 22.7739C16.4315 22.7739 16.7813 23.1237 16.7813 23.5551V25.2003C21.2507 24.8241 24.824 21.2508 25.2003 16.7813H23.5551C23.1236 16.7813 22.7739 16.4315 22.7739 16.0001ZM20.7183 12.2694L16.5601 16.5448C16.2706 16.8424 15.7987 16.8617 15.4858 16.5882L12.3818 13.8746C12.0569 13.5907 12.0238 13.0971 12.3078 12.7723C12.5918 12.4475 13.0853 12.4142 13.4102 12.6983L15.9565 14.9243L19.5982 11.18C19.8991 10.8707 20.3937 10.8639 20.703 11.1646C21.0122 11.4655 21.0191 11.9601 20.7183 12.2694V12.2694Z"
      fill="#66ACEE"
    />
    <path
      d="M16 2.66675C8.64799 2.66675 2.66669 8.64805 2.66669 16.0001C2.66669 23.3521 8.64799 29.3334 16 29.3334C23.3521 29.3334 29.3334 23.3521 29.3334 16.0001C29.3334 8.64805 23.3521 2.66675 16 2.66675ZM16 26.7962C10.047 26.7962 5.20387 21.9531 5.20387 16.0001C5.20387 10.0471 10.047 5.20394 16 5.20394C21.953 5.20394 26.7962 10.0471 26.7962 16.0001C26.7962 21.9531 21.953 26.7962 16 26.7962Z"
      fill="#CCE3F9"
    />
  </SvgIcon>
)
