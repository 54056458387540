import { SvgIcon, SvgIconProps } from '@mui/material'
import React from 'react'

export const ArrowLeftIcon: React.FC<SvgIconProps> = ({
  htmlColor = 'black',
  ...props
}) => (
  <SvgIcon viewBox="0 0 16 16" htmlColor={htmlColor} {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.13799 13.138C7.39834 12.8776 7.39834 12.4555 7.13799 12.1952L3.60939 8.66659L13.9999 8.66659C14.3681 8.66659 14.6666 8.36811 14.6666 7.99992C14.6666 7.63173 14.3681 7.33325 13.9999 7.33325L3.60939 7.33325L7.13799 3.80466C7.39834 3.54431 7.39834 3.1222 7.13799 2.86185C6.87764 2.6015 6.45553 2.6015 6.19518 2.86185L1.52851 7.52851C1.26816 7.78886 1.26816 8.21097 1.52851 8.47132L6.19518 13.138C6.45553 13.3983 6.87764 13.3983 7.13799 13.138Z"
      fill="black"
    />
  </SvgIcon>
)
