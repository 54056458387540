import { SvgIconProps } from '@mui/material'
import React from 'react'

export const FrontLicenseUploadMobileIcon: React.FC<
  Pick<SvgIconProps, 'width' | 'height'>
> = ({ height = 69, width = 120 }) => (
  <svg
    width={width}
    height={height}
    viewBox={`0 0 ${width} ${height}`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="1.5"
      y="1"
      width="98"
      height="63"
      rx="5"
      stroke="#09414E"
      strokeWidth="2"
    />
    <rect x="16.5" y="13" width="68" height="3" rx="1.5" fill="#09414E" />
    <rect x="47.5" y="41" width="37" height="3" rx="1.5" fill="#09414E" />
    <rect x="47.5" y="50" width="13" height="3" rx="1.5" fill="#09414E" />
    <rect x="66.5" y="50" width="18" height="3" rx="1.5" fill="#09414E" />
    <rect x="47.5" y="23" width="37" height="3" rx="1.5" fill="#09414E" />
    <rect x="47.5" y="32" width="22" height="3" rx="1.5" fill="#09414E" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.5 28C16.5 25.2386 18.7386 23 21.5 23H31.5C34.2614 23 36.5 25.2386 36.5 28V38C36.5 40.7614 34.2614 43 31.5 43H21.5C18.7386 43 16.5 40.7614 16.5 38V28ZM21.5 25C19.8431 25 18.5 26.3431 18.5 28V38C18.5 39.6569 19.8431 41 21.5 41H31.5C33.1569 41 34.5 39.6569 34.5 38V28C34.5 26.3431 33.1569 25 31.5 25H21.5Z"
      fill="#09414E"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M26.5 29C25.3954 29 24.5 29.8954 24.5 31C24.5 32.1046 25.3954 33 26.5 33C27.6046 33 28.5 32.1046 28.5 31C28.5 29.8954 27.6046 29 26.5 29ZM22.5 31C22.5 28.7909 24.2909 27 26.5 27C28.7091 27 30.5 28.7909 30.5 31C30.5 33.2091 28.7091 35 26.5 35C24.2909 35 22.5 33.2091 22.5 31Z"
      fill="#09414E"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M26.5 38C23.9429 38 21.7569 39.6001 20.8937 41.8572L19.0256 41.1428C20.1752 38.1371 23.0868 36 26.5 36C29.9132 36 32.8248 38.1371 33.9744 41.1428L32.1064 41.8572C31.2431 39.6001 29.0572 38 26.5 38Z"
      fill="#09414E"
    />
    <circle cx="97.5783" cy="46.5816" r="17.915" fill="white" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M97.58 28.6633C87.6849 28.6633 79.6633 36.6849 79.6633 46.58C79.6633 56.4751 87.6849 64.4967 97.58 64.4967C107.475 64.4967 115.497 56.4751 115.497 46.58C115.497 36.6849 107.475 28.6633 97.58 28.6633ZM97.58 37.6217C98.5695 37.6217 99.3717 38.4238 99.3717 39.4133V44.7883H104.747C105.736 44.7883 106.538 45.5905 106.538 46.58C106.538 47.5695 105.736 48.3717 104.747 48.3717H99.3717V53.7467C99.3717 54.7362 98.5695 55.5383 97.58 55.5383C96.5905 55.5383 95.7883 54.7362 95.7883 53.7467V48.3717H90.4133C89.4238 48.3717 88.6217 47.5695 88.6217 46.58C88.6217 45.5905 89.4238 44.7883 90.4133 44.7883H95.7883V39.4133C95.7883 38.4238 96.5905 37.6217 97.58 37.6217Z"
      fill="#0075E3"
    />
  </svg>
)
