import { SvgIconProps } from '@mui/material'
import React from 'react'

export const FrontLicenseUploadIcon: React.FC<
  Pick<SvgIconProps, 'width' | 'height'>
> = ({ height = 69, width = 121 }) => (
  <svg
    width={width}
    height={height}
    viewBox={`0 0 ${width} ${height}`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="1"
      y="1"
      width="98"
      height="63"
      rx="5"
      stroke="#09414E"
      strokeWidth="2"
    />
    <rect x="16" y="13" width="68" height="3" rx="1.5" fill="#09414E" />
    <rect x="47" y="41" width="37" height="3" rx="1.5" fill="#09414E" />
    <rect x="47" y="50" width="13" height="3" rx="1.5" fill="#09414E" />
    <rect x="66" y="50" width="18" height="3" rx="1.5" fill="#09414E" />
    <rect x="47" y="23" width="37" height="3" rx="1.5" fill="#09414E" />
    <rect x="47" y="32" width="22" height="3" rx="1.5" fill="#09414E" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16 28C16 25.2386 18.2386 23 21 23H31C33.7614 23 36 25.2386 36 28V38C36 40.7614 33.7614 43 31 43H21C18.2386 43 16 40.7614 16 38V28ZM21 25C19.3431 25 18 26.3431 18 28V38C18 39.6569 19.3431 41 21 41H31C32.6569 41 34 39.6569 34 38V28C34 26.3431 32.6569 25 31 25H21Z"
      fill="#09414E"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M26 29C24.8954 29 24 29.8954 24 31C24 32.1046 24.8954 33 26 33C27.1046 33 28 32.1046 28 31C28 29.8954 27.1046 29 26 29ZM22 31C22 28.7909 23.7909 27 26 27C28.2091 27 30 28.7909 30 31C30 33.2091 28.2091 35 26 35C23.7909 35 22 33.2091 22 31Z"
      fill="#09414E"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M25.9998 38C23.4426 38 21.2567 39.6001 20.3934 41.8572L18.5254 41.1428C19.675 38.1371 22.5865 36 25.9998 36C29.413 36 32.3246 38.1371 33.4742 41.1428L31.6061 41.8572C30.7429 39.6001 28.5569 38 25.9998 38Z"
      fill="#09414E"
    />
    <circle cx="97.0786" cy="46.5815" r="17.915" fill="white" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M97.0802 28.6631C87.1851 28.6631 79.1636 36.6847 79.1636 46.5798C79.1636 56.4749 87.1851 64.4964 97.0802 64.4964C106.975 64.4964 114.997 56.4749 114.997 46.5798C114.997 36.6847 106.975 28.6631 97.0802 28.6631ZM97.0802 37.6214C98.0698 37.6214 98.8719 38.4236 98.8719 39.4131V44.7881H104.247C105.236 44.7881 106.039 45.5902 106.039 46.5798C106.039 47.5693 105.236 48.3714 104.247 48.3714H98.8719V53.7464C98.8719 54.7359 98.0698 55.5381 97.0802 55.5381C96.0907 55.5381 95.2886 54.7359 95.2886 53.7464V48.3714H89.9136C88.9241 48.3714 88.1219 47.5693 88.1219 46.5798C88.1219 45.5902 88.9241 44.7881 89.9136 44.7881H95.2886V39.4131C95.2886 38.4236 96.0907 37.6214 97.0802 37.6214Z"
      fill="#0075E3"
    />
  </svg>
)
