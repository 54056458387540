/// <reference types="@types/segment-analytics" />

// Note: if needed we can add Segment node to make this universal

type SegmentAnalyticsJS = SegmentAnalytics.AnalyticsJS

type AnalyticsJS = Readonly<{
  page: SegmentAnalyticsJS['page']
  track: SegmentAnalyticsJS['track']
  identify: SegmentAnalyticsJS['identify']
}>

const analyticsMissing = (method: string) => (): void =>
  console.warn(`Failed to call ${method}, analytics not on page`)

const WarningAnalytics: AnalyticsJS = {
  page: analyticsMissing('page'),
  track: analyticsMissing('track'),
  identify: analyticsMissing('identify'),
}

/**
 * Accesses the global window to retrieve the global segment client.
 *
 * WARNING: Only use this function in a context where it is safe to access
 * the global window. For example in a useEffect hook.
 *
 * If segment isn't initialized or is globally blocked it
 * will return a {@see WarningAnalytics} instance.
 */
export const getSegment = (): AnalyticsJS =>
  window.analytics ?? WarningAnalytics

export const getSegmentBrowserSnippet = (writeKey: string): string => `
  !function(){var analytics=window.analytics=window.analytics||[];if(!analytics.initialize)if(analytics.invoked)window.console&&console.error&&console.error("Segment snippet included twice.");else{analytics.invoked=!0;analytics.methods=["trackSubmit","trackClick","trackLink","trackForm","pageview","identify","reset","group","track","ready","alias","debug","page","once","off","on"];analytics.factory=function(t){return function(){var e=Array.prototype.slice.call(arguments);e.unshift(t);analytics.push(e);return analytics}};for(var t=0;t<analytics.methods.length;t++){var e=analytics.methods[t];analytics[e]=analytics.factory(e)}analytics.load=function(t,e){var n=document.createElement("script");n.type="text/javascript";n.async=!0;n.src="https://cdn.segment.com/analytics.js/v1/"+t+"/analytics.min.js";var a=document.getElementsByTagName("script")[0];a.parentNode.insertBefore(n,a);analytics._loadOptions=e};analytics.SNIPPET_VERSION="4.1.0";
  analytics.load("${writeKey}");
  }}();
  `
