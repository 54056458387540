import { SvgIcon, SvgIconProps } from '@mui/material'
import React from 'react'

export function NewLoanIcon(props: SvgIconProps): JSX.Element {
  return (
    <SvgIcon viewBox="0 0 32 32" {...props}>
      <svg
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M7.81218 8.2959C9.94023 8.11198 11.0722 8.87592 11.5161 9.29197C12.5723 9.82463 13.7372 10.1066 14.92 10.1159C16.1389 10.1136 17.3368 9.79963 18.4 9.20392C18.8961 8.78392 20.0161 8.12392 22.0081 8.2959C22.2654 8.31751 22.5145 8.20055 22.6625 7.98912C22.8104 7.77751 22.8347 7.5034 22.7263 7.26912C22.6179 7.03483 22.393 6.87608 22.1361 6.85198C20.6463 6.65805 19.1357 6.99358 17.8681 7.8L18.6561 5.32393C18.7536 5.01447 18.6979 4.67698 18.5057 4.41554C18.3138 4.15393 18.0086 3.99965 17.6841 4.00001H12.1399C11.8108 3.99876 11.5013 4.15715 11.3099 4.42501C11.1185 4.69287 11.0685 5.0368 11.176 5.34789L12.0321 7.85591C11.2201 7.28393 9.82401 6.66396 7.68815 6.85198H7.68797C7.43011 6.87412 7.20349 7.03216 7.09368 7.26662C6.98404 7.50109 7.00761 7.77624 7.15582 7.98859C7.30386 8.20091 7.55404 8.31805 7.81205 8.29591L7.81218 8.2959Z"
          fill="#00C696"
        />
        <path
          d="M24.3848 20.6521C24.1298 20.1126 23.9802 19.5295 23.9437 18.9342C22.7532 18.8487 21.648 18.2851 20.8798 17.3715C20.1116 16.458 19.7461 15.2724 19.8663 14.0849C19.9864 12.8974 20.5821 11.809 21.5177 11.0679C20.9302 10.5129 20.228 10.0937 19.4609 9.8399C18.1225 10.7071 16.5636 11.1721 14.9689 11.1799C13.35 11.1654 11.7644 10.7179 10.3769 9.88397C6.34086 11.3279 5.9889 16.3439 6.0369 18.5281C6.05173 19.2623 5.89423 19.9897 5.57693 20.652L4.2769 23.3759V23.3761C3.8578 24.2513 3.91744 25.2804 4.43476 26.1013C4.95206 26.9224 5.8546 27.4202 6.82493 27.4201H23.1367C24.1074 27.4206 25.0104 26.9229 25.5285 26.1022C26.0463 25.2813 26.1069 24.252 25.6888 23.3761L24.3848 20.6521ZM15.6698 22.9474H15.512V24.2714C15.512 24.6028 15.2432 24.8714 14.912 24.8714C14.5806 24.8714 14.312 24.6028 14.312 24.2714V22.9474H12.5584C12.227 22.9474 11.9584 22.6787 11.9584 22.3474C11.9584 22.016 12.227 21.7474 12.5584 21.7474H15.6698C16.0255 21.7474 16.3545 21.5576 16.5323 21.2494C16.7102 20.9412 16.7102 20.5615 16.5323 20.2533C16.3545 19.9451 16.0255 19.7552 15.6698 19.7552H14.2741C13.4466 19.7552 12.6822 19.314 12.2686 18.5974C11.8548 17.881 11.8548 16.9983 12.2686 16.2817C12.6822 15.5653 13.4467 15.1238 14.2741 15.1238H14.312V13.6585C14.312 13.327 14.5806 13.0585 14.912 13.0585C15.2432 13.0585 15.512 13.327 15.512 13.6585V15.1242L16.9875 15.124C17.3189 15.124 17.5875 15.3927 17.5875 15.724C17.5875 16.0554 17.3189 16.324 16.9875 16.324H14.2741C13.8754 16.324 13.5072 16.5367 13.3079 16.8818C13.1084 17.2272 13.1084 17.6523 13.3079 17.9977C13.5072 18.3429 13.8754 18.5556 14.2741 18.5556H15.6698C16.4543 18.5556 17.1793 18.974 17.5716 19.6534C17.9638 20.3329 17.9638 21.1701 17.5716 21.8495C17.1793 22.529 16.4543 22.9476 15.6698 22.9476L15.6698 22.9474Z"
          fill="#00C696"
        />
        <path
          opacity="0.4"
          d="M24.2656 11.1816C23.3789 11.1816 22.5283 11.534 21.9012 12.1609C21.2742 12.7881 20.9219 13.6386 20.9219 14.5254C20.9219 15.4122 21.274 16.2627 21.9012 16.8897C22.5283 17.5168 23.3787 17.8692 24.2656 17.8692C25.1524 17.8692 26.003 17.5168 26.6299 16.8897C27.2571 16.2627 27.6092 15.4122 27.6092 14.5254C27.6092 13.6386 27.2571 12.7881 26.6299 12.1611C26.003 11.534 25.1525 11.1816 24.2656 11.1816V11.1816ZM25.6569 15.0122H24.7994V15.9164C24.7967 16.2095 24.5585 16.4457 24.2655 16.4457C23.9724 16.4457 23.7342 16.2095 23.7317 15.9164V15.0122H22.8744C22.5796 15.0122 22.3404 14.7732 22.3404 14.4784C22.3404 14.1836 22.5795 13.9445 22.8744 13.9445H23.7317V13.1341C23.7305 12.9918 23.7862 12.8548 23.8863 12.7537C23.9867 12.6525 24.1231 12.5957 24.2655 12.5957C24.408 12.5957 24.5444 12.6525 24.6446 12.7537C24.7449 12.8548 24.8006 12.9918 24.7994 13.1341V13.9445H25.6571H25.6569C25.9517 13.9445 26.1908 14.1836 26.1908 14.4784C26.1908 14.7732 25.9517 15.0122 25.6569 15.0122H25.6569Z"
          fill="#00C696"
        />
      </svg>
    </SvgIcon>
  )
}
