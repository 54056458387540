import { CustomerFile } from 'src/libs/common/components/types'

export const getCustomerFiles = async (
  token: string,
  customerUuid: string,
  signal: AbortSignal,
): Promise<CustomerFile[]> => {
  const response = await fetch(
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    `${window._env_.REACT_APP_REFI_API_BASE_URL}/v1/customer/${customerUuid}/files`,
    {
      headers: {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      signal,
    },
  )

  if (!response.ok) {
    throw new Error(response.statusText)
  }

  const json: {
    files: CustomerFile[]
  } = await response.json()

  return json.files
}

export const postCustomerFiles = async (
  { customerUuid, token }: { customerUuid: string; token: string },
  body: {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    signed_id: string
    name?: string
    loan_application?: string
    stipulation_type?: number
  },
  signal: AbortSignal,
): Promise<void> => {
  const response = await fetch(
    `${window._env_.REACT_APP_REFI_API_BASE_URL}/v1/customer/${customerUuid}/files`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(body),
      signal,
    },
  )

  if (!response.ok) {
    throw new Error(response.statusText)
  }
}
