import { Alert, AlertTitle, Box, styled, Theme } from '@mui/material'
import { keys } from 'lodash'
import React, {
  FunctionComponent,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { getCustomerFiles } from 'src/api/document'
import { NAVIGATE_BACK_DELTA } from 'src/libs/constants'
import { v4 as uuidv4 } from 'uuid'

import { LoadingIcon } from '../common'
import { sendTrackEvent, Text, trackPage } from '../common/components'
import {
  EErrorFile,
  EFileStatus,
  UploadFileResult,
  UploadProgressFile,
} from '../common/components/types'
import { UserContext } from '../context'
import {
  CaptureAndUploadButton,
  ConfirmationDialog,
  DetailHeader,
  Info,
  UploadProgress,
} from '../documents/document-details/common'
import {
  isAnyFileUploading,
  prepareCustomerFilesForDetailsView,
  setFileProgress,
  uploadDocument,
} from '../documents/utils'
import { useMobile } from '../hooks'

export type WithDocumentProps = {
  title: string
  subTitle: string
  mobileSubTitle?: string
  stipulationType?: number
}

type PreUploadeFile = {
  [key: string]: UploadFileResult
}

export const withDocument = ({
  mobileSubTitle,
  stipulationType,
  subTitle,
  title,
}: WithDocumentProps) => {
  const WrapperComponent: FunctionComponent = () => {
    const [searchParams] = useSearchParams()
    const loanApplication = searchParams.get('loan_application') || undefined
    const navigate = useNavigate()
    const [preUploadedFile, setPreUploadedFile] = useState<PreUploadeFile>({})
    const [showDialog, setShowDialog] = useState(false)
    const isMobileDevice = useMobile()

    const [fileUploadProgress, setFileUploadProgress] =
      useState<UploadProgressFile>({})

    const user = useContext(UserContext)

    const [state, setState] = useState<{
      loading?: true
      loaded?: true
      errored?: true
    }>({ loading: true })

    useEffect(() => {
      const controller = new AbortController()
      sendTrackEvent('Documents Retrieved', {
        stipId: stipulationType,
        customerId: user.customerUuid,
      })
      getCustomerFiles(user.token, user.customerUuid, controller.signal)
        .then((files) => {
          files = files.filter(
            (f) =>
              f.stipulation_type === stipulationType &&
              f.loan_application === searchParams.get('loan_application'),
          )
          const mappedFilesForRepresentation =
            prepareCustomerFilesForDetailsView(files)
          setState({ loaded: true })
          setFileUploadProgress(mappedFilesForRepresentation)
          setPreUploadedFile(
            Object.fromEntries(
              Object.entries(mappedFilesForRepresentation).map(
                ([key, value]) => {
                  return [key, value.file]
                },
              ),
            ),
          )
        })
        .catch((error) => {
          if (!controller.signal.aborted) {
            console.error('Error loading documents: ', error)
            setState({ errored: true })
          }
        })
      return () => controller.abort()
    }, [user, searchParams])

    const startUpload = useCallback(
      async (id: string, file: UploadFileResult) => {
        try {
          if (file?.error) {
            return setFileUploadProgress((fileUploadProgress) =>
              setFileProgress({
                id,
                file,
                fileUploadProgress,
                status: file.status,
                error: file.error,
              }),
            )
          }
          const controller = new AbortController()
          setFileUploadProgress((fileUploadProgress: UploadProgressFile) =>
            setFileProgress({ id, file, fileUploadProgress, controller }),
          )
          await uploadDocument(
            user,
            file.data,
            controller.signal,
            loanApplication,
            stipulationType,
          )
          setFileUploadProgress((fileUploadProgress: UploadProgressFile) =>
            setFileProgress({
              id,
              file,
              fileUploadProgress,
              status: EFileStatus.Succeeded,
            }),
          )
          sendTrackEvent('Documents Upload Succeeded', {
            stipId: stipulationType,
            fileName: file.data.name,
          })
        } catch (error) {
          if (error?.name !== 'AbortError') {
            setFileUploadProgress((fileUploadProgress: UploadProgressFile) =>
              setFileProgress({
                id,
                file,
                fileUploadProgress,
                status: EFileStatus.Failed,
                error: {
                  type: EErrorFile.UploadFailed,
                  message: 'Upload failed, please try again.',
                },
              }),
            )
            sendTrackEvent('Documents Upload Failed', {
              stipId: stipulationType,
              error: {
                type: EErrorFile.UploadFailed,
                message: 'Upload failed, please try again.',
              },
              fileName: file.data.name,
            })
          }
        }
      },
      [user, searchParams],
    )

    const handleUpload = useCallback(
      (results: UploadFileResult[]) => {
        results.forEach((data: UploadFileResult) => {
          const key = uuidv4()
          setPreUploadedFile((prev) => ({
            ...prev,
            [key]: data,
          }))
          startUpload(key, data)
        })
      },
      [startUpload],
    )

    const handleOnCancelButtonClick = useCallback(
      (key: string) => {
        const { controller, file } = fileUploadProgress[key]
        if (file.status === EFileStatus.InProgress) {
          controller?.abort()
          setFileUploadProgress((prevState) => {
            const state = { ...prevState }
            delete state[key]
            return state
          })
        }
        setPreUploadedFile((prevState) => {
          const state = { ...prevState }
          delete state[key]
          return state
        })
      },
      [fileUploadProgress, preUploadedFile],
    )

    useEffect(() => {
      window.scroll({
        top: 0,
        left: 0,
      })
    }, [])

    useEffect(() => {
      trackPage()
    }, [])

    const isFileUploading = isAnyFileUploading(fileUploadProgress)

    return (
      <Container>
        <DetailHeader
          onBackButtonClick={() =>
            isFileUploading
              ? setShowDialog(true)
              : navigate(NAVIGATE_BACK_DELTA)
          }
          title={title}
          subTitle={
            isMobileDevice && mobileSubTitle ? mobileSubTitle : subTitle
          }
        />
        {state.loading ? (
          <LoadingContainer>
            <LoadingIcon sx={{ fontSize: '40px' }} />
            <Text use={['p', 'paragraphs.16']}>
              {`We're pulling up your documents...`}
            </Text>
          </LoadingContainer>
        ) : state.loaded ? (
          <>
            <UploadContainer>
              {keys(preUploadedFile).map((id) => {
                const isUploading =
                  fileUploadProgress[id]?.file?.status ===
                  EFileStatus.InProgress
                return (
                  <UploadCard
                    key={id}
                    sx={{
                      cursor: isUploading ? 'pointer' : 'default',
                    }}
                  >
                    <UploadComponent>
                      <UploadProgress
                        file={fileUploadProgress[id]!.file}
                        progress={fileUploadProgress[id]!.progress}
                        onCancelButtonClick={() =>
                          handleOnCancelButtonClick(id)
                        }
                        onRetryButtonClick={(file: UploadFileResult) =>
                          //TODO: Should this call cancel like license does?
                          startUpload(id, file)
                        }
                      />
                    </UploadComponent>
                  </UploadCard>
                )
              })}
            </UploadContainer>
            <ButtonContainer>
              <StyledInfoDetails />
              <CaptureAndUploadButton
                multiple
                onChange={(results) => handleUpload(results)}
              />
            </ButtonContainer>
          </>
        ) : (
          <Alert severity="error">
            <AlertTitle>Error loading documents.</AlertTitle>
            It&apos;s not you, it&apos;s us.
            <br />
            Call 1-877-445-0070 for assistance or try again later.
          </Alert>
        )}
        <ConfirmationDialog
          open={showDialog}
          onLeaveBtnClick={() => navigate(NAVIGATE_BACK_DELTA)}
          onStayBtnClick={() => setShowDialog(false)}
        />
      </Container>
    )
  }

  return WrapperComponent
}

const StyledInfoDetails = styled(Info)(({ theme }: { theme: Theme }) => ({
  marginRight: 'auto',
}))

const ButtonContainer = styled(Box)(({ theme }: { theme: Theme }) => ({
  display: 'grid',
  gridTemplateColumns: '1fr 263px',
  justifyContent: 'space-between',
  gap: '16px',
  marginTop: '16px',
  [theme.breakpoints.only('xs')]: {
    marginTop: '8px',
    gap: '24px',
    gridTemplateColumns: '100%',
  },
}))

const UploadCard = styled(Box)(({ theme }: { theme: Theme }) => ({
  display: 'grid',
  gridTemplateColumns: '100%',
  gap: '8px',
  padding: 0,
  cursor: 'pointer',
}))

const UploadComponent = styled(Box)(({ theme }: { theme: Theme }) => ({
  display: 'inline-grid',
  gridTemplateColumns: '100%',
  padding: 0,
  background: theme.extension.colors.text.white,
  borderRadius: 16,
  WebkitTapHighlightColor: 'transparent',
}))

const UploadContainer = styled(Box)(({ theme }: { theme: Theme }) => ({
  display: 'grid',
  gridTemplateColumns: '100%',
  gap: '16px',
  background: theme.extension.colors.text.white,
  padding: 0,
  marginTop: 0,
  [theme.breakpoints.only('xs')]: {
    marginTop: 8,
  },
}))

const LoadingContainer = styled(Box)(({ theme }: { theme: Theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  marginTop: 48,
  [theme.breakpoints.down('sm')]: {
    marginTop: 32,
  },
}))

const Container = styled(Box)(({ theme }: { theme: Theme }) => ({
  display: 'grid',
  gridTemplateColumns: '1fr',
  gap: '16px',
}))
