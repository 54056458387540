import { SvgIcon, SvgIconProps } from '@mui/material'
import React from 'react'

export const InfoCircleIcon: React.FC<SvgIconProps> = ({
  htmlColor = 'black',
  ...props
}) => (
  <SvgIcon viewBox="0 0 25 25" htmlColor={htmlColor} {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.6329 4.25C8.21466 4.25 4.63293 7.83172 4.63293 12.25C4.63293 16.6683 8.21466 20.25 12.6329 20.25C17.0512 20.25 20.6329 16.6683 20.6329 12.25C20.6329 7.83172 17.0512 4.25 12.6329 4.25ZM2.63293 12.25C2.63293 6.72715 7.11009 2.25 12.6329 2.25C18.1558 2.25 22.6329 6.72715 22.6329 12.25C22.6329 17.7728 18.1558 22.25 12.6329 22.25C7.11009 22.25 2.63293 17.7728 2.63293 12.25Z"
      fill="#00C696"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.6329 11.25C13.1852 11.25 13.6329 11.6977 13.6329 12.25V16.25C13.6329 16.8023 13.1852 17.25 12.6329 17.25C12.0806 17.25 11.6329 16.8023 11.6329 16.25V12.25C11.6329 11.6977 12.0806 11.25 12.6329 11.25Z"
      fill="#00C696"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.6329 8.25C11.6329 7.69772 12.0806 7.25 12.6329 7.25H12.6429C13.1952 7.25 13.6429 7.69772 13.6429 8.25C13.6429 8.80228 13.1952 9.25 12.6429 9.25H12.6329C12.0806 9.25 11.6329 8.80228 11.6329 8.25Z"
      fill="#00C696"
    />
  </SvgIcon>
)
