import { Blob, DirectUpload } from '@rails/activestorage'
import { keys } from 'lodash'
import { postCustomerFiles } from 'src/api/document'
import { v4 as uuidv4 } from 'uuid'

import {
  CustomerFile,
  EErrorFile,
  EFileStatus,
  UploadFileResult,
  UploadProgressFile,
} from '../common/components/types'

type User = {
  token: string
  customerUuid: string
}

const uploadToCloud = async (
  user: User,
  file: File,
  signal: AbortSignal,
  options?: {
    onProgressCallback?: (event: ProgressEvent) => void
  },
): Promise<Blob> => {
  return new Promise((resolve, reject) => {
    let request: XMLHttpRequest
    const directUpload = new DirectUpload(
      file,
      `${window._env_.REACT_APP_REFI_API_BASE_URL}/v1/direct_upload`,
      {
        directUploadWillCreateBlobWithXHR(xhr) {
          request = xhr
          xhr.setRequestHeader('Authorization', `Bearer ${user.token}`)
          xhr.upload.addEventListener('progress', (event) =>
            options?.onProgressCallback?.(event),
          )
        },
      },
    )
    directUpload.create((error, blob) => {
      if (error) {
        reject(error)
      } else {
        resolve(blob)
      }
    })
    signal.addEventListener('abort', () => {
      request?.abort()
    })
  })
}

export const uploadDocument = async (
  user: User,
  file: File,
  signal: AbortSignal,
  loan_application?: string,
  stipulation_type?: number,
  options?: {
    onProgressCallback?: (event: ProgressEvent) => void
  },
): Promise<void> => {
  const blob = await uploadToCloud(user, file, signal, options)
  await postCustomerFiles(
    { token: user.token, customerUuid: user.customerUuid },
    {
      signed_id: blob.signed_id,
      name: file.name,
      loan_application,
      stipulation_type,
    },
    signal,
  )
}

export const setFileProgress = ({
  error,
  id,
  progress = 0,
  file,
  fileUploadProgress,
  status = EFileStatus.InProgress,
  controller,
}: {
  id: string
  file: UploadFileResult
  fileUploadProgress: {
    [key: string]: {
      file: UploadFileResult
      progress: number
    }
  }
  error?: {
    message: string
    type: EErrorFile
  }
  status?: EFileStatus
  progress?: number
  controller?: AbortController
}) => {
  return {
    ...(fileUploadProgress || {}),
    [id]: {
      ...(fileUploadProgress?.[id] || {}),
      file: {
        ...file,
        error: error,
        status: status,
      },
      progress: progress,
      controller,
    },
  }
}

export const prepareCustomerFilesForDetailsView = (
  files: CustomerFile[],
): UploadProgressFile => {
  return files.reduce<UploadProgressFile>((acc, value: CustomerFile) => {
    acc[value.uuid] = {
      progress: 0,
      file: {
        status: EFileStatus.Succeeded,
        data: { name: value.name ?? 'file' } as File, //hack from original code, difficult to fix
      },
    }
    return acc
  }, {})
}

export type License = {
  front?: CustomerFile
  back?: CustomerFile
}

export const createPairOfLicenseForRepresentation = (
  frontLicenses: CustomerFile[],
  backLicenses: CustomerFile[],
): {
  license: { [key: string]: License }
  frontLicenses: CustomerFile[]
  backLicenses: CustomerFile[]
} => {
  const license: { [key in string]: License } = {}
  const frontLicenseLength = frontLicenses?.length
  const backLicenseLength = backLicenses?.length
  const arr =
    frontLicenseLength > backLicenseLength ? frontLicenses : backLicenses

  arr.forEach((_, key: number) => {
    const uniqueId: string = uuidv4()
    if (frontLicenses[key]) {
      frontLicenses[key] = { ...frontLicenses?.[key], uuid: uniqueId }
    }
    if (backLicenses[key]) {
      backLicenses[key] = { ...backLicenses?.[key], uuid: uniqueId }
    }
    license[uniqueId] = {
      front: frontLicenses?.[key],
      back: backLicenses?.[key],
    }
  })
  return { license, frontLicenses, backLicenses }
}

export const isAnyFileUploading = (fileUploadProgress: UploadProgressFile) => {
  return keys(fileUploadProgress).some((key: string) => {
    const data = fileUploadProgress[key]
    return data.file.status === EFileStatus.InProgress
  })
}
