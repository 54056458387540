/* eslint-disable @typescript-eslint/no-unsafe-member-access */
// eslint-disable-next-line no-restricted-imports
import {
  Alert,
  AlertTitle,
  Avatar,
  Box,
  Button,
  Paper,
  Chip,
  Grid,
  Stack,
  Typography,
  Snackbar,
} from '@mui/material'
import React, { useContext } from 'react'
import { Link } from 'react-router-dom'

import { ExpandedLoanApplication, getLoanApplicationResume } from '../../api'
import { sendTrackEvent } from '../common/components'
import { UserContext } from '../context'

import { CalendarIcon } from './calendar-icon'
import caribouAvatar from './caribou-avatar.png'
import backgroundDocumentsDesktop from './in-progress-loan-background-documents-desktop.svg'
import backgroundDocumentsMobile from './in-progress-loan-background-documents-mobile.svg'
import { ResumeLoanIcon } from './resume-loan-icon'
import { UploadDocumentsIcon } from './upload-documents-icon'

function formatDate(date: Date): string {
  return date.toLocaleString('en-US', {
    month: 'short',
    day: 'numeric',
    year: 'numeric',
  })
}

export function InProgressLoan({
  expandedLoanApplication,
}: {
  expandedLoanApplication: ExpandedLoanApplication
}): JSX.Element {
  const user = useContext(UserContext)

  const [state, setState] = React.useState<{
    pending?: true
    errored?: true
    loaded?: {
      resumeUrl: string
    }
  }>({})

  React.useEffect(() => {
    if (state.loaded) {
      sendTrackEvent('Application Resumed', {
        createdAt: new Date().toISOString(),
        customerUuid: user.customerUuid,
        email: user.email,
        loanApplicationUuid: expandedLoanApplication.loanApplication.uuid,
        path: '/',
      })
      window.location.href = state.loaded.resumeUrl
    }
  }, [state.loaded, expandedLoanApplication, user])

  function onResume() {
    if (!state.pending) {
      setState({ pending: true })
      getLoanApplicationResume(
        user.token,
        expandedLoanApplication.loanApplication.uuid,
      )
        .then(({ resumeUrl }) => {
          setState({ loaded: { resumeUrl } })
        })
        .catch((error) => {
          console.error('Error resuming loan application: ', error)
          setState({ errored: true })
        })
    }
  }

  return (
    <Paper
      elevation={3}
      sx={{
        color: 'white',
        backgroundImage: {
          sm: `url(${backgroundDocumentsDesktop})`,
          xs: `url(${backgroundDocumentsMobile})`,
        },
        backgroundSize: '100% 100%',
        backgroundPosition: 'bottom',
        backgroundColor: '#E8E8E8',
        borderRadius: 4,
        boxShadow: '0px 32px 48px 0px #767C7714, 0px -4px 20px 0px #0000000A',
      }}
    >
      <Stack justifyContent="flex-end">
        <Stack sx={{ p: 3 }}>
          <Stack direction="row" justifyContent="space-between">
            <Grid container direction="row" alignItems="center" spacing={1}>
              <Grid item xs={12} sm="auto">
                <Avatar
                  src={caribouAvatar}
                  alt=""
                  sx={{ width: 48, height: 48 }}
                />
              </Grid>
              <Grid item xs={12} sm="auto">
                <Typography
                  sx={{
                    ml: { xs: 0, sm: 2 },
                    fontSize: { xs: 14, sm: 18 },
                    fontWeight: 500,
                  }}
                >
                  Ready, set, refi!
                </Typography>
              </Grid>
            </Grid>
            <Chip
              label="In progress"
              sx={{
                color: '#09414E',
                fontSize: 14,
                fontWeight: 500,
                borderRadius: 1,
                backgroundColor: 'white',
              }}
            />
          </Stack>
          <Grid container justifyContent="space-between" alignItems="flex-end">
            <Grid item xs={12} sm={8} sx={{ maxWidth: '450px !important' }}>
              <Stack sx={{ mt: 2 }}>
                <Typography
                  component="h3"
                  sx={{
                    fontSize: { xs: 24, sm: 28 },
                    fontWeight: 700,
                    lineHeight: { xs: '32px', sm: '38px' },
                  }}
                >
                  Refinance application on your{' '}
                  {expandedLoanApplication.vehicle?.year &&
                  expandedLoanApplication.vehicle?.make &&
                  expandedLoanApplication.vehicle?.model
                    ? `${expandedLoanApplication.vehicle.year} ${expandedLoanApplication.vehicle.make} ${expandedLoanApplication.vehicle.model}`
                    : 'new vehicle'}
                </Typography>
                <Stack direction="row" alignItems="center" sx={{ mt: 2 }}>
                  <CalendarIcon sx={{ fontSize: 16, stroke: '#191C22' }} />
                  <Typography sx={{ mt: 0.25, ml: 1, fontSize: 14 }}>
                    Application started on{' '}
                    {formatDate(
                      expandedLoanApplication.loanApplication.createdAt,
                    )}
                  </Typography>
                </Stack>
              </Stack>
            </Grid>
            <Grid item xs={12} sm="auto">
              <Box
                sx={{
                  mt: 3,
                  borderRadius: '4px',
                  backgroundColor: 'white',
                }}
              >
                <Button
                  onClick={onResume}
                  endIcon={
                    <ResumeLoanIcon sx={{ fontSize: '24px !important' }} />
                  }
                  sx={{
                    width: '100%',
                    py: 2,
                    px: 4,
                    textTransform: 'none',
                    color: 'black',
                    '&:hover': {
                      color: '#0054A3 !important',
                    },
                  }}
                >
                  <Typography
                    sx={{
                      //color: 'black',
                      fontSize: { xs: 16, sm: 18 },
                      fontWeight: 600,
                    }}
                  >
                    Resume application
                  </Typography>
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Stack>
        <Grid
          container
          spacing={2}
          justifyContent="space-between"
          alignItems="center"
          sx={{ p: 2 }}
        >
          <Grid item xs>
            <Stack direction="row" spacing={2} alignItems="center">
              <Box
                sx={{
                  p: '4px',
                  paddingLeft: '8px',
                  borderRadius: '8px',
                  lineHeight: 1,
                }}
              >
                <UploadDocumentsIcon sx={{ fontSize: 32 }} />
              </Box>
              <Typography
                component="h2"
                sx={{
                  textAlign: 'left',
                  textTransform: 'none',
                  fontSize: { xs: 14, sm: 18 },
                  fontWeight: 500,
                  color: 'black',
                }}
              >
                <b>Get started uploading your documents early&nbsp;&nbsp;</b>
                <Link
                  to={`/documents?loan_application=${expandedLoanApplication.loanApplication.uuid}`}
                  style={{ color: 'black' }}
                >
                  <u>Upload Documents</u>
                </Link>
              </Typography>
            </Stack>
          </Grid>
        </Grid>
      </Stack>
      <Snackbar
        open={state.errored === true}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        autoHideDuration={6000}
        onClose={() => setState({})}
      >
        <Alert severity="error" sx={{ width: '100%' }}>
          <AlertTitle>Error resuming loan application.</AlertTitle>
          It&apos;s not you, it&apos;s us.
          <br />
          Call 1-877-445-0070 for assistance or try again later.
        </Alert>
      </Snackbar>
    </Paper>
  )
}
