import { Box, styled, Theme } from '@mui/material'
import { SxProps } from '@mui/system'
import React, { FC } from 'react'

import { Icon, InfoCircleIcon, Text } from '../../../common/components'

const Container = styled(Box)(({ theme }: { theme: Theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  padding: 16,
  gap: '16px',
  background: theme.extension.colors.surface.cloudGray,
  borderRadius: 4,
  flexGrow: 1,
  '& svg': {
    width: 24,
    height: 24,
  },
}))

export const Info: FC<{ sx?: SxProps }> = ({ sx }) => {
  return (
    <Container sx={sx}>
      <Icon use={InfoCircleIcon} />
      <Text use={['p', 'subheadings.14m']}>
        {'Use JPG, PNG, PDF with max size 10 MB'}
      </Text>
    </Container>
  )
}
