/* eslint-disable @typescript-eslint/no-unsafe-member-access */
// eslint-disable-next-line no-restricted-imports
import { Chip, Paper, Stack, Typography } from '@mui/material'
import React from 'react'

import { ExpandedLoanApplication } from '../../api'

import { CalendarIcon } from './calendar-icon'
import { ExpiredLoanIcon } from './expired-loan-icon'
import { FundedLoanIcon } from './funded-loan-icon'

function formatDate(date: Date): string {
  return date.toLocaleString('en-US', {
    month: 'short',
    day: 'numeric',
    year: 'numeric',
  })
}

export function PreviousLoan({
  expandedLoanApplication,
}: {
  expandedLoanApplication: ExpandedLoanApplication
}): JSX.Element {
  const funded =
    expandedLoanApplication.loanApplication.simplifiedStatus === 'funded'
  return (
    <Paper
      elevation={3}
      sx={{
        p: 2,
        borderRadius: 4,
        boxShadow: '0px 32px 48px 0px #767C7714, 0px -4px 20px 0px #0000000A',
      }}
    >
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        {funded ? (
          <FundedLoanIcon sx={{ fontSize: 32 }} />
        ) : (
          <ExpiredLoanIcon sx={{ fontSize: 32 }} />
        )}
        <Chip
          label={funded ? 'Funded' : 'Expired'}
          sx={{
            fontSize: 14,
            fontWeight: 500,
            borderRadius: 1,
            backgroundColor: funded ? '#00C6961F' : '#FFEBEB',
          }}
        />
      </Stack>
      <Stack sx={{ mt: 2 }}>
        <Typography
          component="h3"
          sx={{ maxWidth: 300, fontSize: 16, fontWeight: 500 }}
        >
          Refinance application on your{' '}
          {expandedLoanApplication.vehicle?.year &&
          expandedLoanApplication.vehicle?.make &&
          expandedLoanApplication.vehicle?.model
            ? `${expandedLoanApplication.vehicle.year} ${expandedLoanApplication.vehicle.make} ${expandedLoanApplication.vehicle.model}`
            : 'new vehicle'}
        </Typography>
        <Stack direction="row" alignItems="center" sx={{ mt: 1 }}>
          <CalendarIcon sx={{ fontSize: 16, stroke: '#191C22' }} />
          <Typography sx={{ mt: 0.25, ml: 1, fontSize: 14 }}>
            {funded
              ? `Funded on ${formatDate(
                  expandedLoanApplication.loanApplication.fundedAt!,
                )}`
              : `Expired on ${formatDate(
                  expandedLoanApplication.loanApplication.expiredAt!,
                )}`}
          </Typography>
        </Stack>
      </Stack>
    </Paper>
  )
}
