import { SvgIconProps } from '@mui/material'
import React from 'react'

export const BackLicenseUploadIcon: React.FC<
  Pick<SvgIconProps, 'width' | 'height'>
> = ({ height = 69, width = 121 }) => (
  <svg
    width={width}
    height={height}
    viewBox={`0 0 ${width} ${height}`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="1"
      y="1"
      width="98"
      height="63"
      rx="5"
      stroke="#09414E"
      strokeWidth="2"
    />
    <rect
      x="20"
      y="26"
      width="16"
      height="3"
      rx="1.5"
      transform="rotate(-90 20 26)"
      fill="#09414E"
    />
    <rect
      x="30"
      y="26"
      width="16"
      height="3"
      rx="1.5"
      transform="rotate(-90 30 26)"
      fill="#09414E"
    />
    <rect
      x="35"
      y="26"
      width="16"
      height="3"
      rx="1.5"
      transform="rotate(-90 35 26)"
      fill="#09414E"
    />
    <rect
      x="41"
      y="26"
      width="16"
      height="3"
      rx="1.5"
      transform="rotate(-90 41 26)"
      fill="#09414E"
    />
    <rect
      x="47"
      y="26"
      width="16"
      height="3"
      rx="1.5"
      transform="rotate(-90 47 26)"
      fill="#09414E"
    />
    <rect
      x="56"
      y="26"
      width="16"
      height="3"
      rx="1.5"
      transform="rotate(-90 56 26)"
      fill="#09414E"
    />
    <rect
      x="61"
      y="26"
      width="16"
      height="3"
      rx="1.5"
      transform="rotate(-90 61 26)"
      fill="#09414E"
    />
    <rect
      x="67"
      y="26"
      width="16"
      height="3"
      rx="1.5"
      transform="rotate(-90 67 26)"
      fill="#09414E"
    />
    <rect
      x="72"
      y="26"
      width="16"
      height="3"
      rx="1.5"
      transform="rotate(-90 72 26)"
      fill="#09414E"
    />
    <rect
      x="78"
      y="26"
      width="16"
      height="3"
      rx="1.5"
      transform="rotate(-90 78 26)"
      fill="#09414E"
    />
    <rect
      x="84"
      y="26"
      width="16"
      height="3"
      rx="1.5"
      transform="rotate(-90 84 26)"
      fill="#09414E"
    />
    <rect
      x="14"
      y="26"
      width="16"
      height="3"
      rx="1.5"
      transform="rotate(-90 14 26)"
      fill="#09414E"
    />
    <circle cx="98.5786" cy="46.5815" r="17.915" fill="white" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M98.5802 28.6631C88.6851 28.6631 80.6636 36.6847 80.6636 46.5798C80.6636 56.4749 88.6851 64.4964 98.5802 64.4964C108.475 64.4964 116.497 56.4749 116.497 46.5798C116.497 36.6847 108.475 28.6631 98.5802 28.6631ZM98.5802 37.6214C99.5698 37.6214 100.372 38.4236 100.372 39.4131V44.7881H105.747C106.736 44.7881 107.539 45.5902 107.539 46.5798C107.539 47.5693 106.736 48.3714 105.747 48.3714H100.372V53.7464C100.372 54.7359 99.5698 55.5381 98.5802 55.5381C97.5907 55.5381 96.7886 54.7359 96.7886 53.7464V48.3714H91.4136C90.4241 48.3714 89.6219 47.5693 89.6219 46.5798C89.6219 45.5902 90.4241 44.7881 91.4136 44.7881H96.7886V39.4131C96.7886 38.4236 97.5907 37.6214 98.5802 37.6214Z"
      fill="#0075E3"
    />
  </svg>
)
