import { AppState, Auth0Provider } from '@auth0/auth0-react'
import React, { FC } from 'react'
import { useNavigate } from 'react-router-dom'

export const Auth0ProviderWithHistory: FC = ({ children }) => {
  const navigate = useNavigate()

  const onRedirectCallback = (appState?: AppState) => {
    navigate(appState?.returnTo || window.location.pathname)
  }
  let params = new URLSearchParams(window.location.search)
  let name = params.get('email')
  return (
    <Auth0Provider
      email={name ?? ''}
      domain={window._env_.AUTH0_DOMAIN}
      clientId={window._env_.AUTH0_CLIENTID}
      audience={window._env_.AUTH0_AUDIENCE}
      redirectUri={window.location.origin}
      onRedirectCallback={onRedirectCallback}
      // auth0-iac reads this prop to determine the type of flow we expect
      // https://github.com/motorefi/auth0-iac/blob/726a883902ce559580066691055f653130d6a606/infra/app/stacks/customer-xp/login.html#L1722
      mode="passwordless"
    >
      {children}
    </Auth0Provider>
  )
}
