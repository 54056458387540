import { SvgIcon, SvgIconProps } from '@mui/material'
import React from 'react'

export const LogoutIcon: React.FC<SvgIconProps> = ({
  htmlColor = 'black',
  ...props
}) => (
  <SvgIcon viewBox="0 0 25 24" htmlColor={htmlColor} {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.5 4C10.8431 4 9.5 5.34315 9.5 7C9.5 8.65685 10.8431 10 12.5 10C14.1569 10 15.5 8.65685 15.5 7C15.5 5.34315 14.1569 4 12.5 4ZM7.5 7C7.5 4.23858 9.73858 2 12.5 2C15.2614 2 17.5 4.23858 17.5 7C17.5 9.76142 15.2614 12 12.5 12C9.73858 12 7.5 9.76142 7.5 7Z"
      fill="#00C696"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.5 20C9.57112 20 7.8346 19.1139 6.83536 17.9708C6.53088 17.6225 6.45154 17.3163 6.46074 17.0651C6.47055 16.7971 6.58641 16.487 6.8472 16.1692C7.38287 15.5164 8.4013 15 9.49998 15H15.5C16.5987 15 17.6171 15.5164 18.1528 16.1692C18.4135 16.487 18.5294 16.7971 18.5392 17.0651C18.5484 17.3163 18.4691 17.6225 18.1646 17.9708C17.1654 19.1139 15.4288 20 12.5 20ZM5.32956 19.2871C6.80212 20.9716 9.15325 22 12.5 22C15.8467 22 18.1978 20.9716 19.6704 19.2871C20.2746 18.5958 20.5676 17.8036 20.5379 16.9919C20.5088 16.1971 20.1744 15.48 19.6988 14.9004C18.7617 13.7585 17.1627 13 15.5 13H9.49998C7.83723 13 6.23821 13.7585 5.30113 14.9004C4.82553 15.48 4.49119 16.1971 4.46208 16.9919C4.43235 17.8036 4.72533 18.5958 5.32956 19.2871Z"
      fill="#00C696"
    />
  </SvgIcon>
)
