import { SvgIcon, SvgIconProps } from '@mui/material'
import React from 'react'

export function ResumeLoanIcon(props: SvgIconProps): JSX.Element {
  return (
    <SvgIcon viewBox="0 0 25 24" {...props}>
      <svg
        width="25"
        height="24"
        viewBox="0 0 25 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.5 2C6.97715 2 2.5 6.47715 2.5 12C2.5 17.5228 6.97715 22 12.5 22C18.0228 22 22.5 17.5228 22.5 12C22.5 6.47715 18.0228 2 12.5 2ZM11.7929 7.29289C12.1834 6.90237 12.8166 6.90237 13.2071 7.29289L17.2071 11.2929C17.3946 11.4804 17.5 11.7348 17.5 12C17.5 12.2652 17.3946 12.5196 17.2071 12.7071L13.2071 16.7071C12.8166 17.0976 12.1834 17.0976 11.7929 16.7071C11.4024 16.3166 11.4024 15.6834 11.7929 15.2929L14.0858 13H8.5C7.94772 13 7.5 12.5523 7.5 12C7.5 11.4477 7.94772 11 8.5 11H14.0858L11.7929 8.70711C11.4024 8.31658 11.4024 7.68342 11.7929 7.29289Z"
          fill="currentColor"
        />
      </svg>
    </SvgIcon>
  )
}
