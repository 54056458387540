import {
  Alert,
  AlertTitle,
  Box,
  styled,
  SvgIconProps,
  Theme,
} from '@mui/material'
import React, { FC, useCallback, useContext, useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { getRequiredCustomerUploads } from 'src/api/loan-application'

import {
  Accordion,
  AccordionWithUploadStatus,
  ArrowRightIcon,
  DocumentUploadedIcon,
  InsuranceIcon,
  LicenseIcon,
  LoadingIcon,
  MiscellaneousIcon,
  OdometerIcon,
  OriginalFinanceContractIcon,
  RegistrationIcon,
  Text,
  trackPage,
} from '../common/components'
import {
  DefaultStipulationTypes,
  RequiredCustomerUpload,
} from '../common/components/types'
import { UserContext } from '../context'

import { DocumentHeader } from './header'

const DocumentBoxContainer = styled(Box)(({ theme }: { theme: Theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
  padding: 0,
  marginTop: theme.spacing(4),
  justifyContent: 'flex-start',
  width: '100%',
}))

const DocumentBoxContentContainer = styled(Box)(
  ({ theme }: { theme: Theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: '24px',
    [theme.breakpoints.down('sm')]: {
      gap: '16px',
    },
    padding: 0,
    justifyContent: 'flex-start',
    width: '100%',
  }),
)

type DocumentCategoryProps = {
  title: string
  documents: {
    uploaded: boolean
    title: string
    text: string
    requiredIcon: React.FC<SvgIconProps>
    onClick: () => void
  }[]
}

const DocumentCategory: FC<DocumentCategoryProps> = ({ documents, title }) => {
  return (
    <DocumentBoxContainer>
      <Text use={['p', 'subheadings.16b']} margin={[0]} color="text.bodyGray">
        {title}
      </Text>
      <DocumentBoxContentContainer>
        {documents.map((document) => {
          const props = {
            ...document,
            key: document.title,
            endIcon: ArrowRightIcon,
            startIcon: document.uploaded
              ? DocumentUploadedIcon
              : document.requiredIcon,
          }
          return document.uploaded ? (
            <AccordionWithUploadStatus {...props} />
          ) : (
            <Accordion {...props} />
          )
        })}
      </DocumentBoxContentContainer>
    </DocumentBoxContainer>
  )
}

export const DocumentsPage = () => {
  const user = useContext(UserContext)
  const [searchParams] = useSearchParams()
  const loanApplication = searchParams.get('loan_application') || undefined
  const navigate = useNavigate()

  const [state, setState] = useState<{
    loading?: true
    loaded?: {
      uploads: RequiredCustomerUpload[]
    }
    errored?: true
  }>({ loading: true })

  const handleClick = useCallback(
    (url: string, stipulationType?: number) => {
      if (stipulationType) {
        searchParams.set('stipulation_type', stipulationType.toString())
      }
      navigate({ pathname: url, search: searchParams.toString() })
    },
    [navigate, searchParams],
  )

  useEffect(() => {
    trackPage()
  }, [])

  useEffect(() => {
    const controller = new AbortController()
    const requiredCustomerUploadsPromise =
      loanApplication !== undefined
        ? getRequiredCustomerUploads(
            user.token,
            loanApplication,
            controller.signal,
          )
        : Promise.resolve([])
    requiredCustomerUploadsPromise
      .then((uploads) => {
        setState({ loaded: { uploads } })
      })
      .catch((error) => {
        if (!controller.signal.aborted) {
          console.error('Error loading documents: ', error)
          setState({ errored: true })
        }
      })
    return () => controller.abort()
  }, [user, loanApplication])

  return (
    <Box>
      <DocumentHeader displayName={user.displayName} />
      {state.loading ? (
        <LoadingContainer>
          <LoadingIcon sx={{ fontSize: '40px' }} />
          <Text use={['p', 'paragraphs.16']}>
            {`We're pulling up your documents...`}
          </Text>
        </LoadingContainer>
      ) : state.loaded ? (
        <>
          <DocumentCategory
            title={'General Documents'}
            documents={[
              {
                title: 'Driver’s License',
                text: `Please provide clear photo of the front and back of your license.`,
                uploaded:
                  state.loaded.uploads.find(
                    (u) =>
                      u.stipulation_type ===
                      DefaultStipulationTypes.DRIVERS_LICENSE_FRONT,
                  )?.uploaded === true &&
                  state.loaded.uploads.find(
                    (u) =>
                      u.stipulation_type ===
                      DefaultStipulationTypes.DRIVERS_LICENSE_BACK,
                  )?.uploaded === true,
                requiredIcon: LicenseIcon,
                onClick: () => handleClick('/documents/license'),
              },
              {
                title: 'Registration',
                text: 'Please provide a clear photo of your vehicle’s registration.',
                uploaded:
                  state.loaded.uploads.find(
                    (u) =>
                      u.stipulation_type ===
                      DefaultStipulationTypes.VEHICLE_REGISTRATION,
                  )?.uploaded === true,
                requiredIcon: RegistrationIcon,
                onClick: () => handleClick('/documents/registration'),
              },
              {
                title: 'Insurance',
                text: 'Please provide a clear photo of your current insurance card.',
                uploaded:
                  state.loaded.uploads.find(
                    (u) =>
                      u.stipulation_type ===
                      DefaultStipulationTypes.VEHICLE_INSURANCE,
                  )?.uploaded === true,
                requiredIcon: InsuranceIcon,
                onClick: () => handleClick('/documents/insurance'),
              },
              {
                title: 'Odometer',
                text: 'Please provide a clear photo of the odometer in your vehicle.',
                uploaded:
                  state.loaded.uploads.find(
                    (u) =>
                      u.stipulation_type === DefaultStipulationTypes.ODOMETER,
                  )?.uploaded === true,
                requiredIcon: OdometerIcon,
                onClick: () => handleClick('/documents/odometer'),
              },
            ]}
          />
          {(() => {
            const defaults = Object.values(DefaultStipulationTypes)
            const lenderUploads = state.loaded.uploads.filter(
              (upload) => !defaults.includes(upload.stipulation_type),
            )
            return (
              lenderUploads.length !== 0 && (
                <DocumentCategory
                  title={'Lender Documents'}
                  documents={lenderUploads
                    .sort((a, b) => a.title.localeCompare(b.title))
                    .map((upload) => ({
                      title: upload.title,
                      text: upload.description,
                      uploaded: upload.uploaded,
                      requiredIcon: OriginalFinanceContractIcon, //this is a generic file icon
                      onClick: () =>
                        handleClick(
                          '/documents/upload',
                          upload.stipulation_type,
                        ),
                    }))}
                />
              )
            )
          })()}
          <DocumentCategory
            title={'Other Documents'}
            documents={[
              {
                title: 'Miscellaneous',
                text: 'Depending on the lender, we may ask for additional documents.',
                uploaded: false, //true will display an uploaded tag, false doesn't
                requiredIcon: MiscellaneousIcon,
                onClick: () => handleClick('/documents/misc'),
              },
            ]}
          />
        </>
      ) : (
        <Alert severity="error">
          <AlertTitle>Error loading documents.</AlertTitle>
          It&apos;s not you, it&apos;s us.
          <br />
          Call 1-877-445-0070 for assistance or try again later.
        </Alert>
      )}
    </Box>
  )
}

const LoadingContainer = styled(Box)(({ theme }: { theme: Theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  marginTop: 48,
  [theme.breakpoints.down('sm')]: {
    marginTop: 32,
  },
}))
