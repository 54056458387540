import { Box, styled, Theme } from '@mui/material'
import React from 'react'
import { ArrowLeftIcon, Icon, Text } from 'src/libs/common/components'

const Container = styled(Box)(({ theme }: { theme: Theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
  [theme.breakpoints.only('xs')]: {
    gap: '24px',
  },
}))

const BackButton = styled(Box)(({ theme }: { theme: Theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  gap: '8px',
  alignItems: 'center',
  cursor: 'pointer',
  '& svg': {
    width: '0.5em',
    height: '0.5em',
  },
}))

const TextContainer = styled(Box)(({ theme }: { theme: Theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
  [theme.breakpoints.only('xs')]: {
    gap: '8px',
  },
  padding: 0,
}))

export type DetailHeaderProps = {
  title?: string
  subTitle?: string
  onBackButtonClick: () => void
}

export const DetailHeader = ({
  onBackButtonClick,
  subTitle,
  title,
}: DetailHeaderProps) => {
  return (
    <Container>
      <BackButton onClick={onBackButtonClick}>
        <Icon use={ArrowLeftIcon} width={16} height={16} />
        <Text use={['p', 'subheadings.14m']} color="text.black">
          {'Back'}
        </Text>
      </BackButton>
      <TextContainer>
        {title && (
          <Text use={['p', 'subheadings.24b']} color="text.black">
            {title}
          </Text>
        )}
        {subTitle && (
          <Text use={['p', 'subheadings.16m']} color="text.bodyGray">
            {subTitle}
          </Text>
        )}
      </TextContainer>
    </Container>
  )
}
