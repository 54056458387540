import { SvgIcon, SvgIconProps } from '@mui/material'
import React from 'react'

export const DashboardIcon: React.FC<SvgIconProps> = ({
  htmlColor = 'black',
  ...props
}) => (
  <SvgIcon viewBox="0 0 25 24" htmlColor={htmlColor} {...props}>
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.82257 16.52C2.82257 15.9677 3.27029 15.52 3.82257 15.52H10.6484C11.2007 15.52 11.6484 15.9677 11.6484 16.52V20.76C11.6484 21.3123 11.2007 21.76 10.6484 21.76H3.82257C3.27029 21.76 2.82257 21.3123 2.82257 20.76V16.52Z"
        fill="#00C696"
      />
      <path
        d="M13.1967 11.24C13.1967 10.6877 13.6444 10.24 14.1967 10.24H21.0225C21.5748 10.24 22.0225 10.6877 22.0225 11.24V20.76C22.0225 21.3123 21.5748 21.76 21.0225 21.76H14.1967C13.6444 21.76 13.1967 21.3123 13.1967 20.76V11.24Z"
        fill="#00C696"
      />
      <path
        d="M2.82257 3.39999C2.82257 2.84771 3.27029 2.39999 3.82257 2.39999H10.6484C11.2007 2.39999 11.6484 2.84771 11.6484 3.39999V12.92C11.6484 13.4723 11.2007 13.92 10.6484 13.92H3.82257C3.27029 13.92 2.82257 13.4723 2.82257 12.92V3.39999Z"
        fill="#00C696"
      />
      <path
        d="M13.1967 3.39999C13.1967 2.84771 13.6444 2.39999 14.1967 2.39999H21.0225C21.5748 2.39999 22.0225 2.84771 22.0225 3.39999V7.64C22.0225 8.19228 21.5748 8.63999 21.0225 8.63999H14.1967C13.6444 8.63999 13.1967 8.19228 13.1967 7.63999V3.39999Z"
        fill="#CCF4EA"
      />
    </svg>
  </SvgIcon>
)
